import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client'
import { AuthContext } from '../../AuthContext'
import { Chart, ArcElement, registerables } from 'chart.js';

import { Doughnut, Line } from 'react-chartjs-2';


import { GET_WAREHOUSE_KPIS } from '../../graphql/QueryManager'
import { AlertApp } from '../Global/AlertApp';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CardInfo from '../Layout/CardInfo';

const WarehousesHome = ({ whsCode }) => {
  const { user: _user } = useContext(AuthContext)
  var currentWeekNumber = require('current-week-number')
  const [performanceIndicators, setPerformanceIndicator] = useState([])
  const { loading, error, data } = useQuery(GET_WAREHOUSE_KPIS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      code: whsCode,
      week: [30, 31, 32, 35, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49]
    }
  })

  useEffect(() => {
    if (!loading) {
      if (error) {
        AlertApp({ message: error, type: 'errorToast' })
      } else {
        setPerformanceIndicator(data.warehouseKPIS)
      }
    }
  }, [loading, error, data])

  Chart.register(ArcElement)
  Chart.register(...registerables);

  function getValueKPI(id) {
    if (performanceIndicators.some((element) => element.id == `${currentWeekNumber(new Date())}${id}`)) {
      const indicator = performanceIndicators.find((element) => element.id == `${currentWeekNumber(new Date())}${id}`)
      return indicator.kpi_Value
    }

  }
  function getUnitKPI(id) {
    if (performanceIndicators.some((element) => element.id == `${currentWeekNumber(new Date())}${id}`)) {
      const indicator = performanceIndicators.find((element) => element.id == `${currentWeekNumber(new Date())}${id}`)
      return `${indicator.kpi_Unit} - ${indicator.kpi_Value}`
    }else{
      return `${currentWeekNumber(new Date())}${id}`
    }
  }
  return (
    <>
      <Row>
        <h5>{`Alamcen ${whsCode}`}</h5>
      </Row>
      <Row>
        {
          performanceIndicators.map((element, index) => {

            if (element.week === currentWeekNumber(new Date())) {
              if (element.kpi !== 'VSR')
                if (element.kpi !== 'PC')
                  if (element.kpi !== 'TPP')
                    if (element.kpi !== 'MT')
                      if (element.kpi !== 'MP')
                      return <Col lg={2} key={`${element.id}-${index}`}>
                        <CardInfo title={`${element.kpi_Name} (${element.kpi_Unit})`} value={parseFloat(element.kpi_Value).toFixed(2)} type={`info`}
                          actionClick={() => {
                            setActivitiesType(element.code)
                            handlerFilter()
                          }} />
                      </Col>

            }
          })
        }
      </Row>
      <Row>
        <Col md={4}>
          <Card >
            <CardHeader color={"info"}>Stock Piso/Almacen</CardHeader>
            <CardBody >
              <Doughnut data={{
                labels: [
                  getUnitKPI('6-1')
                  , getUnitKPI('6-2')
                  , getUnitKPI('6-3')
                ],
                datasets: [{
                  label: [getUnitKPI('6-1')
                    , getUnitKPI('6-2')
                    , getUnitKPI('6-3')],
                  data: [getValueKPI('6-1')
                    , getValueKPI('6-2')
                    , getValueKPI('6-3')],

                }]
              }}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                }}
              />
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">Eficiencia (min)</h3>

              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus"></i>
                </button>
                <button type="button" className="btn btn-tool" data-card-widget="remove">
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <Line
                options={{
                  datasetFill: false
                }}
                data={
                  {
                    labels: performanceIndicators.filter((element) => element.kpi === "HTR").map((element) => element.week),
                    datasets: [
                      {
                        label: 'Eficiencia en la recepción',
                        backgroundColor: 'rgba(60,141,188,0.9)',
                        borderColor: 'rgba(60,141,188,0.8)',
                        pointRadius: false,
                        pointColor: '#3b8bba',
                        pointStrokeColor: 'rgba(60,141,188,1)',
                        pointHighlightFill: '#fff',
                        pointHighlightStroke: 'rgba(60,141,188,1)',
                        data: performanceIndicators.filter((element) => element.kpi === "HTR").map((element) => element.kpi_Value)
                      },
                      {
                        label: 'Eficiencia de surtido',
                        backgroundColor: 'rgba(210, 214, 222, 1)',
                        borderColor: 'rgba(210, 214, 222, 1)',
                        pointRadius: false,
                        pointColor: 'rgba(210, 214, 222, 1)',
                        pointStrokeColor: '#c1c7d1',
                        pointHighlightFill: '#fff',
                        pointHighlightStroke: 'rgba(220,220,220,1)',
                        data: performanceIndicators.filter((element) => element.kpi === "HTS").map((element) => element.kpi_Value)
                      },
                    ]
                  }
                }
              />
            </div>
          </div>

        </Col>
      </Row>
      <Row>
        <Col md={6}>

        </Col>
      </Row>
    </>

  )
}

export default WarehousesHome