import { gql } from "@apollo/client";

export const FIND_BO_ITEM = gql`
  query findBoItem($find: String!) {
    findBoItem(sFindText: $find) {
      itemCode
      itemName
      itmsGrpCod
      codeBars
      purPackMsr
      purPackUn
      salPackMsr
      salPackUn
      indirctTax
      taxCodeAP
      taxCodeAR
      cardCode
      prchseItem
      sellItem
      invntItem
      uDivision
      itemGroup {
        itmsGrpCod
        itmsGrpNam
      }
    }
  }
`;
export const ONE_ITEM = gql`
  query oneItem($code: String!) {
    oneItem(code: $code) {
      itemCode
      itemName
      itmsGrpCod
      codeBars
      purPackMsr
      purPackUn
      salPackMsr
      salPackUn
      indirctTax
      taxCodeAP
      taxCodeAR
      cardCode
      prchseItem
      sellItem
      invntItem
      uDivision
      itemGroup {
        itmsGrpCod
        itmsGrpNam
      }
    }
  }
`;

export const GET_ALL_SALES_ITEMS = gql`
  query allSItems {
    allSItems {
      itemCode
      itemName
      itmsGrpCod
      codeBars
      purPackMsr
      purPackUn
      salPackMsr
      salPackUn
      salUnitMsr
      indirctTax
      taxCodeAP
      taxCodeAR
      cardCode
      prchseItem
      sellItem
      invntItem
      uDivision
      itemGroup {
        itmsGrpCod
        itmsGrpNam
      }
    }
  }
`;

export const GET_ALL_INVNT_ITEMS = gql`
query allInvntItems {
  allInvntItems {
    itemCode
    itemName
    itmsGrpCod
    codeBars
    purPackMsr
    purPackUn
    salPackMsr
    salPackUn
    salUnitMsr
    indirctTax
    taxCodeAP
    taxCodeAR
    cardCode
    prchseItem
    sellItem
    invntItem
    uDivision
    capacityPerPallet
    itemGroup {
      itmsGrpCod
      itmsGrpNam
    }
    buyUnitMsr
    hazardousMaterial
    clase
    invntryUom
    bWeight1
    sWeight1
    iWeight1
  }
}
`;
