import Module from "../Catalogs/Modules/Module";
import ModulesList from "../Catalogs/Modules/ModulesList";
import Permission from "../Catalogs/Permissions/Permission";
import PermissionsList from "../Catalogs/Permissions/PermissionsList";
import Role from "../Catalogs/Roles/Role";
import RolesList from "../Catalogs/Roles/RolesList";
import CommercialProcesseNew from "../Catalogs/Sales/CommercialProcesses/CommercialProcessNew";
import CommercialProcessList from "../Catalogs/Sales/CommercialProcesses/CommercialProcessList";
import CommercialStages from "../Catalogs/Sales/CommercialStages";
import CommercialStagesNew from "../Catalogs/Sales/CommercialStagesNew";
import CommercialSubStages from "../Catalogs/Sales/CommercialProcesses/CommercialSubStages";
import CommercialSubStagesNew from "../Catalogs/Sales/CommercialProcesses/CommercialSubStagesNew";
import Opportunities from "../Catalogs/Sales/Opportunities";
import OpportunitiesNew from "../Catalogs/Sales/OpportunitiesNew";
import SalesVisitNew from "../Catalogs/Sales/SalesVisitNew";
import PurchaseOrderNew from "../Catalogs/Shopping/PurchaseOrderNew";
import PurchaseOrdersList from "../Catalogs/Shopping/PurchaseOrdersList";
import User from "../Catalogs/Users/User";
import UsersList from "../Catalogs/Users/UsersList";
import Home from "../Home";
import NotFound from "../NotFound";
import { InitialSettings } from "../Settings/InitialSettings";
import { CommercialProcessTaskCategoryList } from "../Catalogs/Sales/CommercialProcesses/CommercialProcessTaskCategoryList";
import { CommercialProcessTaskCategoryNew } from "../Catalogs/Sales/CommercialProcesses/CommercialProcessTaskCategoryNew";
import { TransferRequestsList } from "../Catalogs/Transfers/TransferRequestsList";
import { Activities } from "../Catalogs/Sales/Activities";
import ActivitiesNew from "../Catalogs/Sales/ActivitiesNew";
import MyPortfolio from "../Catalogs/BusinessPartners/MyPortfolio";
import DangerousSubstances from "../Catalogs/Warehouses/DangerousSubstances";
import DangerousGoodsClass from "../Catalogs/Warehouses/DangerousSubstances/DangerousGoodsClass";
import ClassesOfDangerousGoodsList from "../Catalogs/Warehouses/DangerousSubstances/ClassesOfDangerousGoodsList";
import UserCalendar from "../Contexts/UserCalendar";
import DangerouesSubstanceNew from "../Catalogs/Warehouses/DangerouesSubstanceNew";
import BinLocations from "../Catalogs/Warehouses/BinLocations";
import InternalTransferList from "../Catalogs/Transfers/InternalTransferList";
import InternalTransferNew from "../Catalogs/Transfers/InternalTransferNew";
import QualityCertificates from "../Catalogs/Warehouses/QualityCertificates";
import SalesInbox from "../Catalogs/Sales/SalesInbox";
import SalesOpportunityNew from "../Catalogs/Sales/SalesOpportunityNew";
import QuotationOpportunities from "../Catalogs/Sales/Opportunities/QuotationOpportunities";
import TagLogList from "../Catalogs/Products/TagLogList";

const PrivateRoutes = [
  {
    index: true,
    element: <Home />,
  },
  // 404
  {
    path: "*",
    element: <NotFound />,
  },
  // Configuracion
  {
    path: "settings/initialSettings",
    element: <InitialSettings />,
  },
  // Modulos
  {
    path: "settings/modules/add",
    element: <Module />,
  },
  {
    path: "settings/modules/edit/:id",
    element: <Module />,
  },
  {
    path: "settings/modules/details/:id/:show",
    element: <Module />,
  },
  {
    path: "settings/modules",
    element: <ModulesList />,
  },
  // Roles
  {
    path: "settings/roles/add",
    element: <Role />,
  },
  {
    path: "settings/roles/edit/:id",
    element: <Role />,
  },
  {
    path: "settings/roles/details/:id/:show",
    element: <Role />,
  },
  {
    path: "settings/roles",
    element: <RolesList />,
  },
  // Permisos
  {
    path: "settings/permissions/add",
    element: <Permission />,
  },
  {
    path: "settings/permissions/edit/:id",
    element: <Permission />,
  },
  {
    path: "settings/permissions/details/:id/:show",
    element: <Permission />,
  },
  {
    path: "settings/permissions",
    element: <PermissionsList />,
  },
  // Usuarios
  {
    path: "settings/users",
    element: <UsersList />,
  },
  {
    path: "settings/users/add",
    element: <User />,
  },
  {
    path: "settings/users/edit/:id",
    element: <User />,
  },
  {
    path: "settings/users/details/:id/:show",
    element: <User />,
  },
  // Entradas de mercancia
  {
    path: "shopping/purchaseorders",
    element: <PurchaseOrdersList />,
  },
  {
    path: "shopping/purchaseorders/edit/:id",
    element: <PurchaseOrderNew />,
  },
  {
    path: "shopping/purchaseorders/details/:id/:show",
    element: <PurchaseOrderNew />,
  },
  // Socio de negocio
  {
    path: "crm/portfolio",
    element: <MyPortfolio />
  },
  // Solicitudes de traslado
  {
    path: "shopping/tansfers",
    element: <TransferRequestsList />,
  },
  // Oportunidades
  {
    path: "crm/opportunities",
    element: <Opportunities />,
  },
  {
    path: "crm/opportunities/add",
    element: <OpportunitiesNew />,
  },
  {
    path: "crm/opportunities/create/:card/:type",
    element: <OpportunitiesNew />,
  },
  {
    path: "crm/opportunities/edit/:id",
    element: <OpportunitiesNew />,
  },
  {
    path: "crm/opportunities/:id/:show",
    element: <OpportunitiesNew />,
  },
  // Actividades
  {
    path: "crm/inbox",
    element: <SalesInbox />,
  },
  {
    path: "crm/activities",
    element: <Activities />,
  },
  {
    path: "crm/activities/add",
    element: <ActivitiesNew />,
  },
  {
    path: "crm/activities/add/:cardCode/:type",
    element: <ActivitiesNew />,
  },
  {
    path: "crm/activities/calendar/:date/:type",
    element: <ActivitiesNew />,
  },
  {
    path: "crm/activities/edit/:id",
    element: <ActivitiesNew />,
  },
  {
    path: "crm/activities/details/:id/:show",
    element: <ActivitiesNew />,
  },
  // Procesos comerciales
  {
    path: "settings/sales/",
    element: <CommercialProcessList />,
  },
  {
    path: "settings/sales/commercialprocesses",
    element: <CommercialProcessList />,
  },
  {
    path: "settings/sales/commercialprocesses/add",
    element: <CommercialProcesseNew />,
  },
  {
    path: "settings/sales/commercialprocesses/edit/:id",
    element: <CommercialProcesseNew />,
  },
  {
    path: "settings/sales/commercialprocesses/:id/:show",
    element: <CommercialProcesseNew />,
  },
  {
    path: "crm/commercialstages",
    element: <CommercialStages />,
  },
  {
    path: "crm/commercialstages/add",
    element: <CommercialStagesNew />,
  },
  {
    path: "crm/commercialstages/edit/:id",
    element: <CommercialStagesNew />,
  },
  {
    path: "crm/commercialstages/details/:id/:show",
    element: <CommercialStagesNew />,
  },
  {
    path: "settings/sales/commercialprocesstaskcategory",
    element: <CommercialProcessTaskCategoryList />,
  },
  {
    path: "settings/sales/commercialprocesstaskcategory/add",
    element: <CommercialProcessTaskCategoryNew />,
  },
  {
    path: "settings/sales/commercialprocesstaskcategory/edit/:id",
    element: <CommercialProcessTaskCategoryNew />,
  },
  {
    path: "settings/sales/commercialprocesstaskcategory/details/:id/:show",
    element: <CommercialProcessTaskCategoryNew />,
  },
  {
    path: "settings/sales/commercialsubstages",
    element: <CommercialSubStages />,
  },
  {
    path: "settings/sales/commercialsubstages/add",
    element: <CommercialSubStagesNew />,
  },
  {
    path: "settings/sales/commercialsubstages/edit/:id",
    element: <CommercialSubStagesNew />,
  },
  {
    path: "settings/sales/commercialsubstages/details/:id/:show",
    element: <CommercialSubStagesNew />,
  },
  {
    path: "crm/sales/salesvisit/edit/:id",
    element: <SalesVisitNew />
  },
  {
    path: 'crm/QuotationOpportunities',
    element: <QuotationOpportunities/>
  },
  {
    path: 'crm/QuotationOpportunities/add',
    element: <SalesOpportunityNew/>
  },
  {
    path: 'crm/QuotationOpportunities/add/:card/:type',
    element: <SalesOpportunityNew/>
  },
  {
    path: 'crm/QuotationOpportunities/edit/:id',
    element: <SalesOpportunityNew/>
  },
  {
    path: 'crm/QuotationOpportunities/details/:id/:show',
    element: <SalesOpportunityNew/>
  },
  // Sustancias peligrosas
  {
    path: "settings/dangeroussubstances/classification",
    element: <DangerousSubstances />
  },
  {
    path: "settings/dangeroussubstances/classification/add",
    element: <DangerouesSubstanceNew />
  },
  {
    path: "settings/dangeroussubstances/classification/edit/:id",
    element: <DangerouesSubstanceNew />
  },
  {
    path: "settings/dangeroussubstances/classification/details/:id/:show",
    element: <DangerouesSubstanceNew />
  },
  {
    path: "quality/dangerousitems",
    element: <ClassesOfDangerousGoodsList />
  },
  {
    path: "quality/dangerousitems/add",
    element: <DangerousGoodsClass />
  },
  {
    path: "quality/dangerousitems/edit/:id",
    element: <DangerousGoodsClass />
  },
  {
    path: "quality/dangerousitems/details/:id/:show",
    element: <DangerousGoodsClass />
  },
  // Almacen Ubicaciones
  {
    path: "warehouses/binlocations",
    element: <BinLocations />
  },
  {
    path: "warehouses/binlocations/transfers",
    element: <InternalTransferList />
  },
  {
    path: "warehouses/binlocations/transfers/add",
    element: <InternalTransferNew />
  },
  {
    path: "quality/QualityCertificates",
    element: <QualityCertificates />
  },
  // Calendario
  {
    path: "Calendar",
    element: <UserCalendar />
  },
  // Articulos
  {
    path: "warehouses/tagloglist",
    element: <TagLogList />,
  }
];

export default PrivateRoutes;
