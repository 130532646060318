import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { ButtonGroup, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import InputApp from '../../Layout/InputApp'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { parse, isDate } from "date-fns";
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { ActionsSBO } from '../../../Types'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { AlertApp } from '../../Global/AlertApp'
import DualListBox from 'react-dual-listbox'
import avatar from '../../../assets/images/AdminLTELogo.png'
import { AuthContext } from '../../../AuthContext'
import { DownloadingFiles, SumarRestarDias } from '../../../Utilities'
// apollo
import {
    ATTACHED_FILE_ACTIVITY,
    CANCEL_ACTIVITY,
    COMPLETE_ACTIVITY,
    CREATE_ACTIVITY,
    GET_ALL_ACTIVITIES_TYPES,
    GET_ONE_ACTIVITY,
    SET_RESPONSE_ACTIVITY,
    UPATE_ACTIVITY
} from '../../../graphql/Catalogs/Activities'
import { GET_ALL_BUSINESS_CATEGORIES_TASK } from '../../../graphql/Catalogs/CommercialProcesses/BusinessTaskCategories'
import { GET_ALL_BUSINESS_PARTNER, GET_ALL_SALES_PERSONS, GET_BUSINESS_PARTNER } from '../../../graphql/QueryManager'
import { GET_BUSINESS_PARTNER_CONTACT_PERSONS } from '../../../graphql/QueryManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DropzoneInput from '../../Layout/DropzoneInput'
import AttachmentToSalesVisit from './Activities/AttachmentToSalesVisit'
import ChooseFromListModal from '../../Global/ChooseFromListModal'

const ActivitiesNew = () => {
    const { id: _id, show, cardCode, type: _type, date: _date } = useParams()
    const { user: userContext } = useContext(AuthContext)
    const navigate = useNavigate()
    const [activity, setActivity] = useState([])
    const [optionsCategory, setOptionsCategory] = useState([])
    const [activitiesTypes, setActivitiesTypes] = useState([])
    const [optionsToDo, setOptionsToDo] = useState([])
    const [selectedToDo, setSelectedToDo] = useState([])
    const [listOfSalesPerson, setListOfSalesPerson] = useState([])
    const [optOfContactPerson, setOptOfContactPerson] = useState([])
    const [waiting, setWaiting] = useState(false)
    const [uploadFiles, setUploadFiles] = useState([])
    const [attachedFile, setAttachedFile] = useState(false)
    const [chooseFromList, setChooseFromList] = useState(false)
    const [cardList, setCarList] = useState([])
    const validationSchema = Yup.object().shape({
        action: Yup.string().required('El tipo de activadad es requerido'),
        title: Yup.string().required('El titulo oportunidad es obligatorio'),
        actionType: Yup.number().notRequired(),
        taskType: Yup.number().notRequired(),
        cardCode: Yup.string().required('El Código de socio de negocio es requerido'),
        cardName: Yup.string().required('La razón social de socio de negocio es requerida'),
        actionDate: Yup.date()
            .typeError('Se esperaba un valor de tipo ${type} pero se obtuvo: ${value}')
            .transform((value, originalValue) => {
                const parsedDate = isDate(originalValue)
                    ? originalValue
                    : parse(originalValue, "yyyy-MM-dd", new Date())

                return parsedDate
            })
            .typeError('Se debe seleccionar una fecha para la visita'),
        // .min(SumarRestarDias(new Date(), '', '1'), `La fecha mínima de inicio es ${SumarRestarDias(new Date(), '', '1')}`),
        actionTime: Yup.string(),
        cprCode: Yup.number().notRequired().default(-1),
    })
    const { handleSubmit, control, setValue, getValues, reset, methods } = useForm({ resolver: yupResolver(validationSchema) })

    const { loading, error, data, refetch } = useQuery(GET_ONE_ACTIVITY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            entry: _id ? parseInt(_id) : 0
        }
    })
    const { loading: loadingTypes, error: errorTypes, data: dataTypes } = useQuery(GET_ALL_ACTIVITIES_TYPES)
    const { loading: loadingCategories, error: errorCategories, data: dataCategories } = useQuery(GET_ALL_BUSINESS_CATEGORIES_TASK)
    const { loading: loadingCard, error: errorCard, data: dataCard } = useQuery(GET_BUSINESS_PARTNER, {
        fetchPolicy: 'cache-and-network',
        variables: {
            code: cardCode ? cardCode : '',
            type: 'c'
        }
    })
    const { loading: loadingSalesPerson, error: errorSalesPerson, data: dataSalesPerson } = useQuery(GET_ALL_SALES_PERSONS, {
        fetchPolicy: 'cache-and-network'
    })

    const [getBusinessPartner] = useLazyQuery(GET_BUSINESS_PARTNER)
    const [getAllBusinessPartner, { loading: loadingAllCadrs }] = useLazyQuery(GET_ALL_BUSINESS_PARTNER)
    const [saveAnswer] = useMutation(SET_RESPONSE_ACTIVITY)
    const [onCreate] = useMutation(CREATE_ACTIVITY)
    const [onCancel] = useMutation(CANCEL_ACTIVITY)
    const [onComplete] = useMutation(COMPLETE_ACTIVITY)
    const [onUpdate] = useMutation(UPATE_ACTIVITY)
    const [onAttachedFile] = useMutation(ATTACHED_FILE_ACTIVITY)
    const [getContacPersons] = useLazyQuery(GET_BUSINESS_PARTNER_CONTACT_PERSONS)
    useEffect(() => {
        if (_type) {
            setValue("action", _type, { shouldDirty: true })
            setValue("actionDate", _date, { shouldDirty: true })
        }
    }, [_type])

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            } else {
                if (_id) {
                    reset(data.oneActivity)
                    setActivity(data.oneActivity)
                    setValue('action', data.oneActivity.activityProperty)
                    setValue('title', data.oneActivity.details)
                    setValue('cardCode', data.oneActivity.cardCode)
                    setValue('cardName', data.oneActivity.cardName)
                    setValue('notes', data.oneActivity.notes)
                    setValue('actionDate', moment(data.oneActivity.activityDate).format("yyyy-MM-DD"))
                    setValue('actionTime', moment(data.oneActivity.activityTime).format('HH:mm'))
                    setValue('cprCode', data.oneActivity.contactPersonCode)

                    getContacPersons({
                        variables: { code: data.oneActivity.cardCode }
                    }).then((result) => {
                        let list = []
                        if (result.data.allContactPersonByCard) {

                            result.data.allContactPersonByCard.map((element) => {
                                return list.push({
                                    value: element.cntctCode,
                                    label: element.name,
                                })
                            })
                        }
                        setOptOfContactPerson(list)
                    })


                }
            }
        }
    }, [loading, data, error])

    useEffect(() => {
        if (!loadingTypes) {
            if (errorTypes) {
                AlertApp({ type: 'errorToast', message: errorTypes })
            } else {
                let list = []

                dataTypes.allActivityTypes.map((element) => {

                    return list.push({
                        value: element.code,
                        label: element.name,
                    })
                })
                setActivitiesTypes(list)
            }
        }
    }, [loadingTypes, errorTypes, dataTypes])

    useEffect(() => {
        if (!loadingCategories) {
            if (errorCategories) {
                AlertApp({ type: 'errorToast', message: errorCategories })
            } else {
                let list = []
                let tasks = []
                dataCategories.allBusinessTaskCategories.map((element) => {
                    element.tasks.map((task) => {
                        return tasks.push({
                            value: task.id,
                            label: task.name
                        })
                    })
                    return list.push({
                        value: element.id,
                        label: element.name,
                        activityID: element.commercialStage.activityID,
                        tasks: element.tasks
                    })
                })
                setOptionsCategory(list)
            }
        }
    }, [loadingCategories, dataCategories, errorCategories])

    useEffect(() => {
        if (!loadingCard) {
            if (errorCard) {
                AlertApp({ type: 'errorTotast', message: errorCard })
            } else {
                if (dataCard.oneBusinessPartner && !_id) {
                    setValue("cardCode", dataCard.oneBusinessPartner.cardCode)
                    setValue("cardName", dataCard.oneBusinessPartner.cardName)
                    let list = []
                    if (dataCard.oneBusinessPartner.contacts) {

                        dataCard.oneBusinessPartner.contacts.map((element) => {
                            return list.push({
                                value: element.cntctCode,
                                label: element.name,
                            })
                        })
                    }
                    setOptOfContactPerson(list)
                }
            }
        }
    }, [loadingCard, errorCard, dataCard])
    useEffect(() => {
        if (!loadingSalesPerson) {
            if (errorSalesPerson) {
                AlertApp({ type: 'errorToast', message: errorSalesPerson })
            }
            let list = []
            if (dataSalesPerson) {

                dataSalesPerson.allSalesPersons.map((element) => {
                    return list.push({
                        value: element.slpCode,
                        label: element.slpName,
                        disabled: element.active === "N",
                    })
                })

            }
            setListOfSalesPerson(list)
        }
    }, [loadingSalesPerson, errorSalesPerson, dataSalesPerson])

    const columns = [
        {
            name: '#',
            selector: row => `${row.cardCode}`,
            width: '80px',
        },
        {
            name: 'Razon social',
            selector: row => `${row.cardName}`,
        },
        {
            name: 'RFC',
            selector: row => row.licTradNum,
        },
        {
            name: 'Territorio',
            selector: row => row.territry.descript,
        },
        {
            name: 'Industria',
            selector: row => row.industry.indName,
        },
        {
            name: '',
            selector: row => <button
                id={`selectButton${row.id}`}
                key={`selectButton${row.id}`}
                onClick={() => OnSelectRow(row)}
                className='btn btn-info btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-share" /></button>
        },
    ]
    const onSeletCategory = (event) => {
        if (event.target.value != "") {
            let task = []

            if (optionsCategory.some((element) => element.value == event.target.value)) {
                const category = optionsCategory.find((element) => element.value == event.target.value)
                category.tasks.map((element) => {
                    return task.push({
                        value: element.id,
                        label: element.name,
                    })
                })
                setOptionsToDo(task)
            }
        }
    }

    const onSeletType = (event) => {
        if (event.target.value != "") {
            let task = []
            if (optionsCategory.some((element) => element.activityID == event.target.value)) {
                const category = optionsCategory.find((element) => element.activityID == event.target.value)

                category.tasks.map((element) => {
                    return task.push({
                        value: element.id,
                        label: element.name,
                    })
                })
                setOptionsToDo(task)
            }
        }
    }

    const onHandlerFindCode = async (event) => {
        event.preventDefault()
        try {

            setWaiting(true)

            const code = getValues('cardCode')
            const result = await getBusinessPartner({
                variables: {
                    code,
                    type: 'C'
                }
            })
            if (result.data.oneBusinessPartner) {

                setValue("cardName", result.data.oneBusinessPartner.cardName)
                setValue("slpCode", result.data.oneBusinessPartner.slpCode)
                if (userContext.Rol === 4 || userContext.Rol === 1) {

                }
                const contacs = await getContacPersons({
                    variables: { code: result.data.oneBusinessPartner.cardCode }
                })

                let list = []
                if (contacs.data.allContactPersonByCard) {

                    contacs.data.allContactPersonByCard.map((element) => {
                        return list.push({
                            value: element.cntctCode,
                            label: element.name,
                        })
                    })
                }
                setOptOfContactPerson(list)
            }

        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }

    const confirmTask = async (element) => {

        try {
            setWaiting(true)
            const result = await saveAnswer({
                variables: {
                    id: element.id,
                    answer: getValues(`content-answer-${element.id}`),
                    baseID: activity.assignedTask.id,
                    entry: activity.activityCode,
                }
            })
            AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }

    const attachedFileHandler = async (task) => {

        if (uploadFiles.length > 0) {
            console.log(task)
            try {
                const result = await AlertApp({
                    title: `¿Desea agregar un comentario para el archivo?`,
                    input: "text",
                    type: 'question',
                    okButtonText: 'Confirmar'
                })
                const listFiles = Object.values(uploadFiles).map((file) => {

                    return file
                })
                setWaiting(true)
                onAttachedFile({
                    variables: {
                        input: {
                            id: activity.assignedTask.id,
                            docEntry: parseInt(_id),
                            taskID: task.id,
                            freeText: result.isConfirmed ? result.value : task.task.name,
                            attached: listFiles[0],
                        }
                    },
                    context: {
                        headers: {
                            "GraphQL-Preflight": 1
                        }
                    },
                    refetchQueries: refetch
                })
                AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
            } catch (error) {
                AlertApp({ type: "errorToast", message: error })
            } finally {
                setWaiting(false)
            }
        }
    }

    const submitHandler = async (data) => {
        try {
            setWaiting(true)
            if (!_id) {
                const taskList = []
                selectedToDo.map((row) => {
                    taskList.push(row)
                })
                const result = await onCreate({
                    variables: {
                        input: {
                            action: data.action,
                            actionDate: data.actionDate,
                            actionTime: data.actionTime,
                            cardCode: data.cardCode,
                            cntctSbjct: 2,
                            cntctType: parseInt(data.activityType),
                            details: data.title,
                            notes: data.notes,
                            opportunityID: -1,
                            title: data.title,
                            cprCode: data.cprCode,
                            slpCode: userContext.SalesPersonCode,
                            tasksID: taskList
                        }
                    }
                })
                AlertApp({ type: 'infoToast', message: 'Operacion finalizada con exito' })

                navigate(-1)
            } else {

                let cancelUpdate = false

                if ((moment(data.actionDate).format("yyyy-MM-DD") != moment(activity.activityTime).format("HH:mm") || moment(activity.activityTime).format("HH:mm") != data.actionTime)) {
                    const result = await AlertApp({ type: 'question', message: `La fecha de la actividad cambio ¿Se va re agendar con la fecha ${data.actionDate.toLocaleString('es-MX', { dateStyle: 'medium' })}  hora: ${data.actionTime}` })
                    cancelUpdate = result.isConfirmed
                }
                if (cancelUpdate) {
                    await onUpdate({
                        variables: {
                            input: {
                                id: activity.assignedTask.id,
                                docEntry: activity.activityCode,
                                actionDate: data.actionDate,
                                cntctType: data.actionType,
                                title: data.title,
                                actionTime: data.actionTime,
                                notes: data.notes,
                            }
                        }
                    })
                }
            }
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }
    const submitReSchedule = async () => {

        const result = await AlertApp({
            title: `Re agendar la <b>${ActionsSBO.find((row) => row.value == activity.activityProperty).label}</b>`,
            input: "datetime-local",
            type: 'question',
            okButtonText: 'Confirmar'
        })

        if (result.isConfirmed)
            if (result.value != "") {
                // onUpdateDate({
                //     variables: {
                //         input: {
                //             docEntry: activity.activityCode,
                //             id: activity.assignedTask.id,
                //             actioDate: result.value
                //         }
                //     }
                // })
                AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                // navigate("/crm/activities")
            } else {
                AlertApp({ type: 'error', message: 'Es necesario proporcionar una fecha valida' })
            }
    }
    const submitEndActivity = async (data) => {

        const result = await AlertApp({
            title: `Finalizar la <b>${activity.activityProperty}</b>`,
            html: `<strong>Comentarios finales</strong>`,
            input: "textarea",
            type: 'question',
            okButtonText: 'Confirmar'
        })

        if (result.isConfirmed)
            if (result.value != "") {
                await onComplete({
                    variables: {
                        input: {
                            docEntry: activity.activityCode,
                            id: activity.assignedTask?.id ? activity.assignedTask.id : 0,
                            finalComment: result.value
                        }
                    }
                })
                AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                navigate(-1)
            } else {
                AlertApp({ type: 'error', message: 'Es necesario proporcionar un comentario final' })
            }
    }
    const submitCancelActivity = async (data) => {

        var htmlString = `
        Se va <b class='text-danger'>Cancelar</b> la <b>${ActionsSBO.find((row) => row.value == activity.activityProperty).label}</b> con <p>${activity.contactPerson.firstName} ${activity.contactPerson.lastName}</p>
        <b>${activity.cardCode} - ${activity.cardName}</b>
        
        <p>Describe el motivo de la cancelación de la ${ActionsSBO.find((row) => row.value == activity.activityProperty).label}:</p>
      `
        const result = await AlertApp({
            html: htmlString,
            input: "text",
            type: 'question',
            okButtonText: 'Confirmar'
        })

        if (result.isConfirmed) {
            if (result.value != "") {
                await onCancel({
                    variables: {
                        input: {
                            docEntry: activity.activityCode,
                            id: activity.assignedTask?.id ? activity.assignedTask.id : 0,
                            reason: result.value
                        }
                    }
                })
                AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                navigate(-1)
            } else {
                AlertApp({ type: 'error', message: 'Es necesario proporcionar el motivo de la cancelación' })
            }
        }
    }
    const addButtonsTools = () => {
        return <>
            <ButtonGroup>
                <a className="btn btn-secondary" onClick={() => setAttachedFile(true)} hidden={!_id}>
                    <FontAwesomeIcon icon={"file-arrow-up"} />{` Archivos (${activity.attachments ? activity.attachments.length : 0})`}
                </a>
            </ButtonGroup>
        </>
    }
    const onChooseFromList = async () => {
        try {
            const result = await getAllBusinessPartner({
                variables: {
                    code: '',
                    type: 'C'
                }
            })

            setCarList(result.data.allBusinessPartners)
            setChooseFromList(!chooseFromList)
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    const OnSelectRow = async (row) => {
        try {
            setWaiting(true)
            setValue('cardCode', row.cardCode, { shouldDirty: true })


            setValue("cardName", row.cardName, { shouldDirty: true })
            setValue("slpCode", row.slpCode, { shouldDirty: true })

            const contacs = await getContacPersons({
                variables: { code: row.cardCode }
            })

            let list = []
            if (contacs.data.allContactPersonByCard) {

                contacs.data.allContactPersonByCard.map((element) => {
                    return list.push({
                        value: element.cntctCode,
                        label: element.name,
                    })
                })
            }
            setOptOfContactPerson(list)

        } catch (error) {
            console.log(error)
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
            setChooseFromList(!chooseFromList)
        }
    }
    return (
        <DashboardLayout >
            <CardBox
                title={`${_id ? `Actividad ${_id}` : 'Nueva Actividad'}${activity.closed === "tYES" ? " (Finalizada)" : ''}`}
                btnSubmit={(!show || activity.closed === "tYES") ? handleSubmit(submitHandler) : null}
                btnReturn={() => navigate("/crm/activities")}
                btnUseMultiSubmit={_id ? (show ? false : (activity.closed === "tNO")) : false}
                actionTools={addButtonsTools()}
                btnMultiSubmit={[{ action: handleSubmit(submitEndActivity), text: 'Finalizar' }
                    , { action: handleSubmit(submitCancelActivity), text: 'Cancelar' }
                ]}
                loading={loading || loadingCard || loadingCategories || waiting}
                statusColor={`${_id ? activity.cardStatus : 'card-info'}`}
                footer
                content={<>
                    <FormProvider   {...methods}>
                        <Row>
                            <Col>
                                <form>
                                    <Row>
                                        <Col>
                                            <InputApp name={"action"} label={"Actividad"} inputType='select' listOfOptions={ActionsSBO} control={control} isReadOnly={show || _id} />
                                        </Col>
                                        <Col>
                                            <InputApp name={"title"} label={"Objetivo"} control={control} isReadOnly={show || activity.closed === "tYES"} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputApp name={"activityType"} label={"Tipo"} inputType='select' listOfOptions={activitiesTypes} control={control} changeAction={(e) => onSeletType(e)} isReadOnly={show || activity.closed === "tYES"} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputApp inputType={`${_id ? 'text' : 'textButton'}`} name={"cardCode"} label={"Código de negocio"} control={control}
                                                onKeyDown={(e) => { if (e.key === 'Enter') { onHandlerFindCode(e) } }}
                                                isReadOnly={show || _id || activity.closed === "tYES"} isLoading={loadingAllCadrs} faIcon='fa-solid fa-list'
                                                buttonActionToolTip={`Lista de clientes`} buttonAction={() => onChooseFromList()} />
                                        </Col>
                                        <Col>
                                            <InputApp name={"cardName"} label={"Nombre de negocio"} control={control} isReadOnly={show || _id || activity.closed === "tYES"} />
                                        </Col>
                                    </Row>
                                    {(userContext.Rol === 4 || userContext.Rol === 1) && (
                                        <Row>
                                            <InputApp name={"salesEmployee"} label={"Vendedor"} control={control} inputType='select' listOfOptions={listOfSalesPerson} />
                                        </Row>
                                    )}
                                    <Row>
                                        <Col>
                                            <InputApp name={"cprCode"} label={"Contacto"} inputType='select' listOfOptions={optOfContactPerson} control={control} isReadOnly={show || activity.closed === "tYES"} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputApp name={"actionDate"} label={"Fecha de actividad"} inputType='date' control={control} isReadOnly={show || activity.closed === "tYES"} />
                                        </Col>
                                        <Col>
                                            <InputApp name={"actionTime"} label={"Hora de actividad"} inputType='datetime' control={control} isReadOnly={show || activity.closed === "tYES"} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputApp inputType='textarea' name={"notes"} label={"Comentarios"} control={control} isReadOnly={show || activity.closed === "tYES"} />
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                            <Col hidden={_id}>
                                <Row>
                                    <Col>
                                        <InputApp name={"cntctType"} label={"Categoria"} inputType='select' listOfOptions={optionsCategory} control={control} changeAction={(e) => onSeletCategory(e)} isReadOnly={show || _id || activity.closed === "tYES"} />

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className='card-secondary'>
                                            <CardHeader>Tareas</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <DualListBox
                                                            id={"tasksID"}
                                                            name={"tasksID"}
                                                            options={optionsToDo}
                                                            selected={selectedToDo}
                                                            onChange={(newValue) => setSelectedToDo(newValue)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Row>

                            </Col>
                            {!loading && (
                                <Col hidden={!_id}>
                                    <Row>
                                        <Col>
                                            <Card className='card card-secondary'>
                                                <CardHeader>
                                                    {`Tareas ${activity.taskContent?.length} / ${activity.taskContent?.filter((s) => s.completed).length}`}
                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-info" href={`${location.pathname}/add`}>
                                                            <FontAwesomeIcon icon={"plus-square"} />{" Agregar"}
                                                        </button>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    {activity.taskContent?.map((element) => {

                                                        if (element.task.validationType == "Q") {
                                                            return <div key={element.id} className='post clearfix'>
                                                                <div className='user-block'>
                                                                    <img className="img-circle img-bordered-sm" src={avatar} alt="User Image" />
                                                                    <span className='username'>
                                                                        <span className="text-primary" >{element.name}</span>
                                                                    </span>
                                                                    <span className='description'></span>
                                                                    <p>
                                                                        {element.task.name}
                                                                    </p>
                                                                    <p>
                                                                        <button type={'button'} className="btn btn-success btn-sm mr-2" onClick={() => confirmTask(element)}><i className="far fa-file-alt mr-1"></i> Guardar</button>
                                                                    </p>
                                                                </div>
                                                                <InputApp name={`content-answer-${element.id}`} defaultValue={element.answer} additionalClass='form-control-sm' placeholder="Escribe un comentario" control={control} />
                                                            </div>
                                                        }
                                                        else {
                                                            return <div key={element.id} className='post clearfix'>
                                                                <div className='user-block'>
                                                                    <img className="img-circle img-bordered-sm" src={avatar} alt="User Image" />
                                                                    <span className='username'>
                                                                        <span className="text-primary" >{element.name}</span>
                                                                    </span>
                                                                    <span className='description'></span>
                                                                    <p>
                                                                        {element.task.name}
                                                                    </p>

                                                                </div>
                                                                {!element.completed ? (
                                                                    <DropzoneInput label={"Cargar"} uploadMode={false} noKeyboard={true} setUploadFiles={setUploadFiles} uploadAction={() => attachedFileHandler(element)} />
                                                                ) : (
                                                                    <InputApp inputType='textButton' buttonActionText={"Descargar"} faIcon={"fa-file-arrow-down"} isReadOnly={true}
                                                                        name={`content-answer-${element.id}`} defaultValue={element.attachment.fileName} additionalClass='form-control'
                                                                        placeholder="Escribe un comentario" control={control} buttonAction={() => DownloadingFiles("ActAtt", element.attachment.id, element.attachment.fileName, element.attachment.fileExt)} />
                                                                )}

                                                            </div>
                                                        }
                                                    })}

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            )}

                        </Row>
                    </FormProvider>
                    <AttachmentToSalesVisit _activity={activity} _dialog={attachedFile} _setDialog={setAttachedFile} _attachment={onAttachedFile} _files={activity.attachments} _refetch={refetch} />
                    <ChooseFromListModal
                        chooseFromList={chooseFromList}
                        setChooseFromList={setChooseFromList}
                        data={cardList}
                        columns={columns}
                        filter={true}
                        selectableRows={false}
                        title={`Lista de socios de negocio`}
                        size={"xl"} />
                </>}
            />
        </DashboardLayout>
    )
}

export default ActivitiesNew