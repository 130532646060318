import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '../../Layout/DashboardLayout';
import CardBox from '../../Layout/CardBox';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { InputApp } from '../../Layout/InputApp';
import { Col, Row, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { GET_ALL_ROLES } from '../../../graphql/Catalogs/Roles';
import { AlertApp } from '../../Global/AlertApp';

// apollo
import { CREATE_USER, GET_USER, UPDATE_USER } from '../../../graphql/Catalogs/Users';
import { GET_ALL_SALES_PERSONS } from '../../../graphql/QueryManager';
import { GET_ALL_WAREHOUSES, GET_ONE_WAREHOUSE_PARAMETERS } from '../../../graphql/Catalogs/Warehouse';
import DataTableApp from '../../Layout/DataTableApp';

const User = () => {
    const { id: _id, show } = useParams()
    const [user, setUser] = useState([])
    const [listOfRoles, setListOfRoles] = useState([])
    const [optSalesPerson, setOptSalesPerson] = useState([])
    const [optWarehouses, setOptWarehouses] = useState([])
    const [warehouseParameters, setWarehouseParameters] = useState({})
    const [waiting, setWaiting] = useState(false)
    const navigate = useNavigate()

    const { loading, error, data } = useQuery(GET_USER, {
        variables: {
            id: _id ? parseInt(_id) : 0,
        },
        fetchPolicy: 'cache-and-network'
    })

    const { loading: loadingRoles, error: errorRoles, data: dataRoles, refetch: reloadRoles } = useQuery(GET_ALL_ROLES, {
        fetchPolicy: 'cache-and-network'
    })

    const { loading: loadingSalesPerson, error: errorSalesPerson, data: dataSalesPerson } = useQuery(GET_ALL_SALES_PERSONS)
    const { loading: loadingWarehouses, error: errorWarehouses, data: dataWarehouses } = useQuery(GET_ALL_WAREHOUSES, {
        fetchPolicy: 'cache-and-network'
    })
    const [getWhsParameters, { loading: loadingWhsParameters, error: errorWhsParameters, data: dataWhsParameters }] = useLazyQuery(GET_ONE_WAREHOUSE_PARAMETERS)
    const [create] = useMutation(CREATE_USER)
    const [update] = useMutation(UPDATE_USER)

    const validationSchema = Yup.object().shape({
        code: Yup.string().required('El código del operador es requerido'),
        fullName: Yup.string().required('El nombre del operador es requerido'),
        email: Yup.string().required('El email del operador es requerido'),
        password: Yup.string().required('la contraseña es obligatoria')
            .test("is-greater", "La contraseña debe tener min 4 caracteres", function (value) {

                return value.length >= 4
            }),
        roleID: Yup.string().required('Se debe seleccionar el rol')
            .test("is-greater", "Se debe seleccionar una opcion", function (value) {
                return value !== "0"
            }),
        salesPersonCode: Yup.number(),
    })

    const { handleSubmit, reset, control, methods, setValue } = useForm({
        resolver: yupResolver(validationSchema),
    })

    useEffect(() => {

        if (!loading) {
            if (error) {

                AlertApp('error', error.ApolloError)
            }
            if (data.oneUser) {
                setUser(data.oneUser)
                reset(data.oneUser)
            }
        }

    }, [loading, error, data])

    useEffect(() => {
        if (!loadingRoles) {
            if (errorRoles) {
                AlertApp('error', errorRoles)
            }
            let list = []
            if (dataRoles) {

                dataRoles.allRoles.map((element) => {
                    return list.push({
                        value: element.id,
                        label: element.name,
                    })
                })

            }
            setListOfRoles(list)
        }
    }, [loadingRoles, errorRoles, dataRoles])

    useEffect(() => {
        if (!loadingSalesPerson) {
            if (errorSalesPerson) {
                AlertApp({ type: 'error', message: errorSalesPerson })
            }

            let list = []
            if (dataSalesPerson) {

                dataSalesPerson.allSalesPersons.map((element) => {
                    return list.push({
                        value: element.slpCode,
                        label: element.slpName,
                        email: element.email,
                    })
                })

            }
            setOptSalesPerson(list)
        }
    }, [loadingSalesPerson, errorSalesPerson, dataSalesPerson])

    useEffect(() => {
        if (!loadingWarehouses) {
            if (errorWarehouses) {
                AlertApp({ message: errorWarehouses, type: 'errorToast' })
            } else {
                let list = []
                list.push({
                    value: '-1',
                    label: 'Ningun almacen por defecto',
                })
                if (dataWarehouses) {

                    dataWarehouses.allWarehouses.map((element) => {
                        return list.push({
                            value: element.whsCode,
                            label: `[${element.whsCode}] ${element.whsName}`,
                        })
                    })

                }
                setOptWarehouses(list)
            }
        }
    }, [loadingWarehouses, errorWarehouses, dataWarehouses])

    useEffect(() => {
        if (!loadingWhsParameters) {
            if (errorWhsParameters) {
                AlertApp({ message: errorWhsParameters, type: 'errorToast' })
            } else {
                if(dataWhsParameters)
                setWarehouseParameters(dataWhsParameters.oneWarehouseParameterByWhsCode)
            }
        }
    }, [loadingWhsParameters, errorWhsParameters, dataWhsParameters])

    const setEmail = async (event) => {
        if (optSalesPerson.some((element) => element.value == event.target.value)) {
            const salesPerson = optSalesPerson.find((element) => element.value == event.target.value)
            if (salesPerson.email) {
                setValue('email', salesPerson.email)
                setValue('fullName', salesPerson.label)
                const result = await AlertApp({ type: 'question', message: '¿Asignar el correo como código de usuario?' })
                if (result.isConfirmed) {
                    setValue('code', salesPerson.email)
                }
            }

        }
    }

    const submitHandler = async (data) => {
        try {

            setWaiting(true)
            if (!_id) {
                const result = await create({
                    variables: {
                        input: {
                            code: data.code,
                            email: data.email,
                            fullName: data.fullName,
                            password: data.password,
                            roleID: parseInt(data.roleID),
                            salesPersonCode: parseInt(data.salesPersonCode)
                        }
                    }
                })
                AlertApp({ message: 'Operacion finalizada con exito' })
                navigate('/settings/users')
            } else {
                const result = await update({
                    refetchQueries: reloadRoles,
                    variables: {
                        id: parseInt(_id),
                        input: {
                            code: data.code,
                            email: data.email,
                            fullName: data.fullName,
                            password: data.password,
                            roleID: parseInt(data.roleID),
                            salesPersonCode: parseInt(data.salesPersonCode)
                        }
                    }
                })
                AlertApp({ message: 'Operacion finalizada con exito' })
                navigate('/settings/users')
            }
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setWaiting(false)
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                title={"Usuario"}
                loading={waiting || loading || loadingRoles}
                footer={true}
                btnSubmit={!show ? handleSubmit(submitHandler) : null}
                btnReturn={() => navigate("/settings/users")}
                content={
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <Row>
                                <Col>
                                    <Card className={"card-secondary"}>
                                        <CardHeader>
                                            <CardTitle>General</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <InputApp name={"code"} control={control} label={"Código"} tabIndex={1} isReadOnly={show} />
                                                </Col>
                                                <Col>
                                                    <InputApp name={"fullName"} control={control} label={"Nombre completo"} tabIndex={2} isReadOnly={show} />
                                                </Col>
                                                <Col>
                                                    <InputApp type={"password"} name={"password"} control={control} label={"Contraseña"} tabIndex={3} isReadOnly={show} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <InputApp inputType={"select"} name={"roleID"} control={control} label={"Rol"} tabIndex={4} listOfOptions={listOfRoles} isReadOnly={show} />
                                                </Col>
                                                <Col>
                                                    <InputApp name={"email"} control={control} label={"Correo"} tabIndex={5} isReadOnly={show} />
                                                </Col>
                                                <Col>
                                                    <InputApp name={"branchCode"} control={control} label={"Sucursal"} tabIndex={5} isReadOnly={show} />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Card className={"card-secondary"}>
                                                <CardHeader><CardTitle>Datos de empleado</CardTitle></CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <InputApp inputType='select' name={"salesPersonCode"} label={"Empleado del departamento de ventas"} control={control} tabIndex={6} isReadOnly={loadingSalesPerson || show} listOfOptions={optSalesPerson} changeAction={(e) => setEmail(e)} />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className={"card-secondary"}>
                                                <CardHeader>
                                                    <CardTitle>Datos de almacen</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <InputApp inputType='select' name={"warehouseCode"} label={"Codigo de alamcen principal"} control={control} tabIndex={6} isReadOnly={loadingWarehouses || show} listOfOptions={optWarehouses} changeAction={async (e) => await getWhsParameters({
                                                                variables:{
                                                                    code: e.target.value
                                                                }
                                                            })}/>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <InputApp name={"whsCodeDecrease"} label={warehouseParameters.whsDecreaseName} control={control} isReadOnly={true}/>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>


                        </form>
                    </FormProvider>
                }
            />
        </DashboardLayout>
    );
};

export default User;