import React from 'react'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import InputApp from '../../../Layout/InputApp'
import { ContactTypes } from '../../../../Types';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertApp } from '../../../Global/AlertApp';

const AddProspectContact = ({ _dialog = false, _setDialog, onCreate = null, activity = null}) => {
    const validationSchema = Yup.object().shape({
        cntType: Yup.string().required("Seleccionar un tipo de contacto"),
        firstName: Yup.string().required("El nombre es necesario"),
        lastName: Yup.string().required("El apellido es necesario"),
        tel1: Yup.string().notRequired(),
        tel2: Yup.string().notRequired(),
        address: Yup.string().notRequired(),
        e_MailL: Yup.string().email(),
    })

    const { handleSubmit, control, methods, reset } = useForm({
        resolver: yupResolver(validationSchema),
    })

    const toggle = () => _setDialog(!_dialog)

    const onSubmit = async (data) => {
        try {
            const result = onCreate({
                variables: {
                    input: {
                        cntType: data.cntType,                        
                        firstName: data.firstName,
                        lastName: data.lastName,
                        tel1: data.tel1,
                        tel2: data.tel2,
                        e_MailL: data.e_MailL,
                        activityCode: activity.activityCode,
                        opportunityID: -1                       
                    }
                }
            })
            AlertApp({ type: 'infoToast', message: 'Operacion finalizada con exito' })
            toggle()
        } catch (error) {
            AlertApp({type: 'errorToast', message: error})
        }
    }
    return (
        <Modal isOpen={_dialog} toggle={toggle} size='md' className={`card card-primary card-outline`}>
            <ModalHeader>{`Registrar Contacto (Prospeccion ${activity?.activityCode})`}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <InputApp name={"cntType"} label={`Tipo de contacto`} inputType='select' listOfOptions={ContactTypes} control={control} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputApp name={`firstName`} label={"Nombre"} control={control} />
                    </Col>
                    <Col>
                        <InputApp name={`lastName`} label={"Apellido"} control={control} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputApp name={`e_MailL`} label={"Correo electrónico"} control={control} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputApp name={`tel1`} label={"Teléfono 1"} control={control} />
                    </Col>
                    <Col>
                        <InputApp name={`tel2`} label={"Teléfono móvil"} control={control} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputApp name={`address`} label={"Dirección"} control={control} />
                    </Col>
                </Row>
                <ModalFooter>
                    <div className="control-btn-box">
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
                            <FontAwesomeIcon icon={"fa-solid fa-file-circle-check"} /> {`Agregar`}
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={toggle}>
                            Cancelar
                        </button>
                    </div>
                </ModalFooter>
            </ModalBody>
        </Modal>
    )
}

export default AddProspectContact