import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Progress, ButtonGroup, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu, Card, CardBody, CardHeader } from 'reactstrap'
import InputApp from '../../../Layout/InputApp'
import { useForm } from 'react-hook-form'
import DataTableApp from '../../../Layout/DataTableApp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColorsOfOpptStatesSBO, CurrencyCodesTypesSBO, ListOfOpptStatesSBO, ListOfOpptStatesValuesSBO } from '../../../../Types'
import { AlertApp } from '../../../Global/AlertApp'
import { currencyFormat } from '../../../../Utilities'
import moment from 'moment'
import ButtonApp from '../../../Layout/ButtonApp'

const CloseOpportunityModal = ({ _Opportunity, _setOpportunity, _dialog, _setDialog, _listOfStages = [], _listOfDefectCause = [], _getRate, _update, _user, _deleteRow, _refetch }) => {
    const [listOfItems, setListOfItems] = useState([])
    const toggle = () => {
        _setDialog(!_dialog)
    }
    const { control, getValues, reset, setValue } = useForm()

    useEffect(() => {
        if (_Opportunity) {
            reset(_Opportunity)
            let list = []

            if (_Opportunity.wspOpptItems)
                _Opportunity.wspOpptItems.map((element) => {
                    let cloneElement = { ...element }

                    cloneElement.inputQuantity = <input type={"number"} id={`rowQuantity_${element.id}_${element.lineNum}`} className={"form-control form-control-sm"} defaultValue={element.quantity} readOnly={readOnlyRows} />
                    cloneElement.inputValue = <input type={"number"} id={`rowValue_${element.id}_${element.lineNum}`} className={"form-control form-control-sm"} defaultValue={parseFloat(element.price).toFixed(2)} prefix={element.currency} readOnly={readOnlyRows} />

                    cloneElement.saveButton = <button className={'btn btn-danger btn-sm'} onClick={() => onHandlerDeleteRow(cloneElement)}><FontAwesomeIcon icon="fa-solid fa-trash" /></button>
                    list.push(cloneElement)
                })
            setListOfItems(list)
        }
    }, [_Opportunity])

    const readOnlyRows = !(_user.Rol === 4 || _user.Rol === 1)

    const columns = [

        {
            name: 'SKU',
            selector: row => row.itemCode,
            width: '80px',
        },
        {
            name: 'Descripcion',
            selector: row => row.itemName,

        },
        {
            name: 'Volumen',
            selector: row => row.inputQuantity,
        },
        {
            name: 'Precio',
            selector: row => row.inputValue,
        },
        {
            name: 'Precio Total',
            selector: row => `${row.lineTotal.toFixed(2)} ${row.currency}`,
        },

        {
            name: '',
            selector: row => row.saveButton,
        },
    ]

    const onCurrencyChange = () => {
        const list = []

        listOfItems.map((element) => {
            element.currency = getValues('currency')
            return list.push(element)
        })
        _setOpportunity(prev => ({ ...prev, currency: getValues('currency') }))

        setListOfItems(list)
    }
    const onDateChange = async () => {
        try {
            const dateNow = moment(new Date()).format("YYYY-MM-DD")
            const dateNew = moment(getValues('start')).format("YYYY-MM-DD")

            if (Date.parse(dateNew) > Date.parse(dateNow)) {
                AlertApp({ type: 'errorToast', message: `La fecha no es valida` })
                setValue('start', _Opportunity.start)
            } else {
                const result = await _getRate({
                    variables: {
                        date: getValues('start'),
                        currency: getValues('currency')
                    }
                })

                if (result.data.rate) {
                    setValue('rate', result.data.rate)
                }
                _setOpportunity(prev => ({ ...prev, start: getValues('start') }))
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    const onHandlerDeleteRow = async (element) => {
        try {
            if (_Opportunity.opprId) {
                const result = await _deleteRow({
                    variables: {
                        id: parseInt(_Opportunity.opprId),
                        row: element.id
                    },
                    refetchQueries: _refetch
                })

                if (result.errors)
                    AlertApp({ type: 'errorToast', message: result.errors })

                if (result.data.deleteQuotationOpportunityContent) {
                    AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                }
            } else {

            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }

    const onSubmit = async () => {
        try {

            let list = []
            let index = 0;
            listOfItems.map((row) => {
                list.push({
                    index: row.id,
                    quantity: parseFloat(document.getElementById(`rowQuantity_${row.id}_${row.lineNum}`).value),
                    price: parseFloat(document.getElementById(`rowValue_${row.id}_${row.lineNum}`).value),

                })
                index++;
            })

            const result = await _update({
                variables: {
                    id: _Opportunity.id,
                    input: {
                        currency: getValues("currency"),
                        currentStage: parseInt(getValues("currentStage")),
                        reason: parseInt(getValues("numReason")) === 0 ? null : parseInt(getValues("numReason")),
                        status: ListOfOpptStatesValuesSBO[_Opportunity.status],
                        maxLocalTotal: listOfItems.length === 0 ? 1.0 : listOfItems.reduce((n, { lineTotal }) => n + lineTotal, 0),
                        memo: _Opportunity.memo,
                        items: list,
                    }
                },
                refetchQueries: _refetch
            })
            if (result.errors) {
                AlertApp({ type: 'errorToast', message: result.errors })
            }
            if (result.data.updateQuotationOpportunity) {
                AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
                toggle()
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }

    }
    return (
        <Modal isOpen={_dialog} toggle={toggle} size='xl' className={`card card-${ColorsOfOpptStatesSBO[_Opportunity.status]} card-outline`}>
            <ModalHeader>
                {`Oportunidad ${(_Opportunity.opprId ? _Opportunity.opprId : _Opportunity.id)} ${_Opportunity.title}`}
            </ModalHeader>
            <ModalBody>
                <form>
                    <Row>
                        <Col sm={{ size: 3, offset: 9 }}>
                            <InputApp name={"currentStage"} label={"Etapa"} inputType={"select"} control={control} listOfOptions={_listOfStages} changeAction={(e) => _setOpportunity((prevState) => ({ ...prevState, currentStage: e.target.value }))} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} >
                            <InputApp name={"start"} label={"Fecha de inicio"} inputType={'date'} control={control} isReadOnly={readOnlyRows} changeAction={() => onDateChange()} />
                        </Col>
                        <Col sm={{ size: 3, offset: 6 }}>
                            <InputApp name={"status"} label={`Status`} inputType={"select"} listOfOptions={ListOfOpptStatesSBO} control={control} changeAction={(e) => _setOpportunity((prevState) => ({ ...prevState, status: e.target.value }))} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <Row>
                                <Col>
                                    <InputApp name={"currency"} label={"Moneda"} inputType={"select"} listOfOptions={CurrencyCodesTypesSBO} control={control} isReadOnly={readOnlyRows} changeAction={() => onCurrencyChange()} />
                                </Col>
                                <Col>
                                    <InputApp name={"rate"} label={"Tasa"} control={control} isReadOnly={true} />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={{ size: 3, offset: 6 }}>
                            <InputApp name={"numReason"} label={`Razones`} inputType={"select"} listOfOptions={_listOfDefectCause} defaultOptionValue={"-1"} control={control} isReadOnly={_Opportunity.status !== "L"} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ size: 3, offset: 9 }}>
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>Total:</th>
                                            <td>{`${currencyFormat(listOfItems.length > 0 ? listOfItems.reduce((n, { lineTotal }) => n + lineTotal, 0) : 0, _Opportunity.currency)}`}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <DataTableApp
                                data={listOfItems}
                                columns={columns}
                            />
                        </Col>
                    </Row>
                </form>
            </ModalBody>
            <ModalFooter>
                <div className="control-btn-box">
                    <button type={"submit"} className="btn btn-primary" onClick={() => onSubmit()}>
                        Guardar
                    </button>
                    <button type={"button"} className="btn btn-secondary" onClick={toggle}>
                        Cerrar
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default CloseOpportunityModal