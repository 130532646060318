import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { useQuery } from '@apollo/client'
import Swal from 'sweetalert2'
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows'

// apollo
import { GET_ALL_USERS } from '../../../graphql/Catalogs/Users'
import CardBox from '../../Layout/CardBox'
import DataTableApp from '../../Layout/DataTableApp'


const UsersList = () => {
  const [list, setList] = useState([])

  const columns = [
    {
      name: '#',
      selector: row => row.id,
      sortable: false,
      width: '80px',
    },
    {
      name: 'Código',
      selector: row => row.code,
      sortable: false,
    },
    {
      name: 'Nombre',
      selector: row => row.name,
      sortable: false,
    },
    {
      name: 'Correo',
      selector: row => row.email,
      sortable: false,
    },
    {
      name: '',
      selector: row => row.actions,
      sortable: false,
    },
  ]

  const { loading, error, data } = useQuery(GET_ALL_USERS, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loading) {
      if (error) {
        Swal.fire({
          title: 'Ocurrio un error inesperado',
          text: error,
          icon: 'error'
        })
      }
      let list = []
      data.allUsers.map((element) => {

        let buttons = <ActionButtonsForRows
          baseUrl={'settings/users'}
          element={element}
          editButton
          showButton
          deleteButton
        />

        return list.push({
          id: element.id,
          code: element.code,
          name: element.fullName,
          email: element.email,
          actions: buttons
        })
      })

      setList(list)
    }
  }, [loading, error, data])
  return (
    <DashboardLayout>
      <CardBox
        title={"Usuarios"}
        loading={loading}
        cardTools
        content={<>
          <DataTableApp
            data={list}
            columns={columns}
            progressPending={loading}
            pagination
            striped
            dense
            filter
          />
        </>}
      />
    </DashboardLayout>
  )
}

export default UsersList