import React, { useEffect, useState, useContext } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// apollo

import { GET_SALESPERSON_KPIS } from '../../graphql/QueryManager'
import CalendarApp from '../Layout/CalendarApp'
import { GET_CALENDAR } from '../../graphql/Contexts/Calendar'
import { AlertApp } from '../Global/AlertApp'
import { AuthContext } from '../../AuthContext'
import CardInfo from '../Layout/CardInfo'

const SalesPersonHome = () => {
    const { user: _user } = useContext(AuthContext)
    const navigate = useNavigate()
    const [dataEvents, setEvents] = useState([])
    const [showAll, setShowAll] = useState(false)
    const [performanceIndicators, setPerformanceIndicator] = useState([])
    var currentWeekNumber = require('current-week-number')

    const { loading: loadingCalendar, data: dataCalendar, error: errorCalendar } = useQuery(GET_CALENDAR, {
        fetchPolicy: 'cache-and-network'
    })
    const { loading, error, data } = useQuery(GET_SALESPERSON_KPIS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            code: _user.SalesPersonCode,
            week: [40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52]
        }
    })
    useEffect(() => {
        if (!loadingCalendar) {
            if (errorCalendar) {
                AlertApp({ type: 'errorToast', message: errorCalendar })
            } else {
                setEvents(dataCalendar.calendar)

            }
        }
    }, [loadingCalendar, dataCalendar, errorCalendar])

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ message: error, type: 'errorToast' })
            } else {
                setPerformanceIndicator(data.salesPersonKPIS)
            }
        }
    }, [loading, error, data])

    return (
        <>
            <Row>
                {
                    performanceIndicators.map((element, index) => {
                        if (element.week === currentWeekNumber(new Date())) {
                            if (element.kpi === 'APC' || element.kpi === 'OPSO')
                                return <Col lg={2} key={`${element.id}-${index}`}>
                                    <CardInfo title={`${element.kpi_Name} (${element.kpi_Unit})`} value={parseFloat(element.kpi_Value).toFixed(2)} type={`info`}
                                    />
                                </Col>

                        }
                    })
                }
            </Row>
            {/* <Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={8}>

                                {
                                    performanceIndicators.map((element, index) => {
                                        if (element.week === currentWeekNumber(new Date())) {
                                            if (element.kpi === 'OPML' || element.kpi === 'OPMO' || element.kpi === 'OPMW')
                                                return <div key={`${element.id}-${index}`} className={"progress-group"}>
                                                    {`${element.kpi_Name}`}

                                                </div>

                                        }
                                    })
                                }

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Row> */}
            <Row>
                <Col lg={10}>
                    <Card>
                        <CardHeader>
                            <CardTitle>
                                {$`Mis actividades ${showAll ? '' : "(Hoy)"}`}
                            </CardTitle>

                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <CalendarApp
                                        events={dataEvents}
                                        initialView="timeGridWeek"
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default SalesPersonHome