import React, { useState } from 'react'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import DropzoneInput from '../../../Layout/DropzoneInput'
import InputApp from '../../../Layout/InputApp'
import { useForm } from 'react-hook-form'
import { AlertApp } from '../../../Global/AlertApp'
import DataTableApp from '../../../Layout/DataTableApp'
import { DownloadingFiles } from '../../../../Utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

const AttachmentToSalesVisit = ({ _activity, _dialog, _setDialog, _attachment, _files ,_refetch}) => {
    const [uploadFiles, setUploadFiles] = useState([])
    const toggle = () => _setDialog(!_dialog)
    const { control, getValues, setValue } = useForm()

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
        },
        {
            name: 'Nombre',
            selector: row => row.fileName,
            sortable: false,
        },
        {
            name: 'Fecha del anexo',
            selector: row => moment(row.attachmentDate).format('YYYY-MM-DD'),
            sortable: false,
        },
        {
            name: '',
            selector: row => <button
                id={`request${row.id}`}
                key={`request${row.id}`}
                onClick={() => DownloadingFiles("ActAtt", row.id, row.fileName, row.fileExt)}
                className='btn btn-success btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-download" /></button>,
            sortable: false,
        },
    ]

    const onSubmit = async () => {
        try {
            const listFiles = Object.values(uploadFiles).map((file) => {

                return file
            })

            const result = await _attachment({
                variables: {
                    input: {
                        id: _activity.assignedTask.id,
                        docEntry: _activity.activityCode,
                        taskID: -1,
                        freeText: getValues('freeText'),
                        attached: listFiles[0],
                    }
                },
                context: {
                    headers: {
                        "GraphQL-Preflight": 1
                    }
                }
            })
            setValue('freeText', "")
            AlertApp({ type: 'okToast', message: "Operacion finalizada con exito" })
            _refetch()
            toggle()
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    return (
        <Modal isOpen={_dialog} toggle={toggle} size='lg'>
            <ModalHeader>{`Adjuntar documentos a la Actividad: ${_activity.activityCode}`}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <InputApp name={"freeText"} label={"Texto libre"} control={control} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DropzoneInput label={"Cargar"} noClick={true} noKeyboard={true} setUploadFiles={setUploadFiles} uploadAction={() => onSubmit()} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTableApp
                            data={_files}
                            columns={columns}
                            striped
                            dense
                            selectable
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className="control-btn-box">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setValue('freeText', "")
                        toggle()
                    }}>
                        Cerrar
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default AttachmentToSalesVisit