import React, { useContext, useState, useEffect } from 'react';
import { types } from '../../Types';
import { AuthContext } from '../../AuthContext';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

// apollo
import { DECRYPT } from '../../graphql/Auth/Identity';
import { LoadingLayer} from '../Layout/LayoutLoading';
export const ProtectedRoute = () => {
    const { dispatch } = useContext(AuthContext)
    const [isAuth, setAuth] = useState(false)
    const [loading, setLoading] = useState(true)
    const token = localStorage.getItem('token')
    const navigate = useNavigate()

    const [decrypt] = useMutation(DECRYPT, {
        variables: {
            token: token,
        }, onError: (error) => {
           
        }
    })

    useEffect(() => {
        const validateToken = async () => {
            try {
                
                setLoading(true)
                const { data: result } = await decrypt()
                if (result) {
                    
                    dispatch({
                        type: types.login,
                        payload: {
                            logged: true,
                            UserId: result.decryptValue.id,
                            UserName: result.decryptValue.fullName,
                            Email: result.decryptValue.email,
                            SessionID: result.decryptValue.password,
                            Rol: parseInt(result.decryptValue.roleID),
                            SalesPersonCode : parseInt(result.decryptValue.salesPersonCode),
                            Warehouse: result.decryptValue.warehouseCode
                        },
                    },)
                    setAuth(true)
                    setLoading(false)
                } else {
                    navigate('/login')
                }

            } catch (error) {
           
            }
        }
        if (token) {
            validateToken()
        } else {
            setAuth(false)
            navigate('/login')
        }
    }, [])
    return (
        <>
            {!loading ? (
                isAuth ? <Outlet />  : <Navigate to="/login" />
            ) : (
    <LoadingLayer />
)}
        </>
    )
}  