import React, { useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import InputApp from '../../Layout/InputApp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AlertApp } from '../../Global/AlertApp'

const ProductTagDialog = ({ _dialog, _setDialog, _boItem = null, _batchs = [], _send = null, _onSend = false }) => {
    const [boItem, setBoItem] = useState({})
    const [hasPalletCapacity, setHasPalletCapacity] = useState(true)
    const [selectedBatch, setSelectedBatch] = useState({ content: { onHandQty: 0 } })
    const { control, reset, getValues } = useForm()

    const toggle = () => {
        _setDialog(!_dialog)
    }
    useEffect(() => {
        if (_boItem) {
            let cloneElement = { ..._boItem }
            cloneElement.onHand = 0
            cloneElement.pallets = 0
            cloneElement.lotAbs = -1
            setHasPalletCapacity(cloneElement.capacityPerPallet > 0)
            setBoItem(cloneElement)
            reset(cloneElement)

            setSelectedBatch({})
        }
    }, [_boItem])
    useEffect(() => {
        if (selectedBatch.onHandQty > 0) {
            let cloneElement = { ..._boItem }
            cloneElement.lotAbs = selectedBatch.absEntry
            cloneElement.onHand = (selectedBatch.onHandQty / boItem.bWeight1).toFixed(2)
            cloneElement.onHandQuantity = selectedBatch.onHandQty
            if (cloneElement.capacityPerPallet > 0)
                cloneElement.pallets = ((selectedBatch.onHandQty / boItem.bWeight1) / boItem.capacityPerPallet).toFixed(2)

            setBoItem(cloneElement)
            reset(cloneElement)
        }
    }, [selectedBatch])

    const recalculatePallets = (capacityPerPallet) => {
        if (selectedBatch.onHandQty > 0) {
            let cloneElement = { ..._boItem }
            cloneElement.lotAbs = selectedBatch.absEntry
            cloneElement.onHand = (selectedBatch.onHandQty / boItem.bWeight1).toFixed(2)
            cloneElement.capacityPerPallet = capacityPerPallet
            cloneElement.onHandQuantity = selectedBatch.onHandQty
            if (capacityPerPallet > 0)
                cloneElement.pallets = ((selectedBatch.onHandQty / boItem.bWeight1) / capacityPerPallet).toFixed(2)

            setBoItem(cloneElement)
            reset(cloneElement)
        }
    }
    const onSubmit = async () => {
        try {

            await _send({
                variables: {
                    input: {
                        absEntry: selectedBatch.absEntry,
                        attached: "",
                        attachedExt: "",
                        itemCode: selectedBatch.itemCode,
                        tags: parseInt(getValues('requiredTags')),
                        distNumber: selectedBatch.distNumber,
                        freeText: "",
                        whsCode: selectedBatch.whsCode,
                        tagType: 1,
                        reasonForRequest: ""
                    }
                }
            })
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    return (
        <Modal isOpen={_dialog} toggle={toggle} size='xl'>
            <ModalHeader>
                {`Etiquetas del producto: ${boItem.itemCode}`}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <InputApp inputType='text' control={control} name={"itemName"} label={"Descripción"} isReadOnly />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputApp inputType='select' control={control} name={"lotAbs"} label={"No Lote"} defaultOptionValue={-1} listOfOptions={_batchs} changeAction={(event) => {
                            if (_batchs.some((e) => e.value == event.target.value))
                                setSelectedBatch(_batchs.find((e) => e.value == event.target.value).content)
                        }} />
                    </Col>
                    <Col>
                        <InputApp inputType='text' control={control} name={"onHandQuantity"} label={`En Stock`} defaultValue={selectedBatch.onHandQty} isReadOnly />
                    </Col>
                    <Col>
                        <InputApp inputType='text' control={control} name={"onHand"} label={`${boItem.purPackMsr} ${boItem.bWeight1}  ${boItem.buyUnitMsr}`} isReadOnly />
                    </Col>
                    <Col>
                        <InputApp inputType='text' control={control} name={"capacityPerPallet"} label={"Capacidad/Tarima"} isReadOnly={hasPalletCapacity} onlyNumbers changeAction={(event) => {
                            if (!isNaN(parseFloat(event.target.value)))
                                recalculatePallets(parseFloat(event.target.value))
                        }} />
                    </Col>
                    <Col>
                        <InputApp inputType='text' control={control} name={"pallets"} label={"Tarimas"} isReadOnly />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={2}>
                        <InputApp inputType='text' control={control} name={"requiredTags"} label={"Cantidad de etiquetas"} onlyNumbers />
                    </Col>
                    <Col md={2}>
                        <InputApp inputType='text' control={control} name={"tagsInStock"} label={"Etiquetas generadas"} onlyNumbers isReadOnly />
                    </Col>
                    <Col md={2} className={`align-self-center`}>
                        <button type="button" className="btn btn-primary" onClick={() => onSubmit()}>
                            Imprimir
                        </button>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className="control-btn-box">
                    <button type="button" className="btn btn-secondary" onClick={toggle}>
                        Cancelar
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ProductTagDialog