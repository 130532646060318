import React, { useState, useEffect } from 'react'
import { Col, Modal, ModalBody, ModalHeader, ModalFooter, Row } from 'reactstrap'
import { FormProvider, useForm } from 'react-hook-form'
import InputApp from '../../../Layout/InputApp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { parse, isDate } from "date-fns";
import { AlertApp } from '../../../Global/AlertApp'
import DataTableApp from '../../../Layout/DataTableApp'
import ButtonApp from '../../../Layout/ButtonApp'
const RegisterProspectingModal = ({ _dialog = false, _setDialog, onCreate = null, activity = null, activitiesTypes = [], contacts = [], toggleContacts = null }) => {
    const [types, setTypes] = useState([])
    const toggle = () => _setDialog(!_dialog)

    const validationSchema = Yup.object().shape({
        activityProperty: Yup.string().required('El tipo de actividad es obligatorio'),
        title: Yup.string().notRequired(),
        activityDate: Yup.date()
            .typeError('Se esperaba un valor de tipo ${type} pero se obtuvo: ${value}')
            .transform((value, originalValue) => {
                const parsedDate = isDate(originalValue)
                    ? originalValue
                    : parse(originalValue, "yyyy-MM-dd", new Date())

                return parsedDate
            })
            .typeError('Se debe seleccionar una fecha para la visita'),
        actionTime: Yup.string(),
        notes: Yup.string(),
    })
    const { control, setValue, methods, handleSubmit, reset } = useForm({ resolver: yupResolver(validationSchema) })

    useEffect(() => {
        if (activity) {
            reset(activity)
        } else {

            setValue("activityDate", new Date(), { shouldDirty: true })
            setValue("activityProperty", "Prospección de clientes", { shouldDirty: true })
            setValue("notes", "")

        }
    }, [activity])

    useEffect(() => {
        let list = []

        activitiesTypes.map((element) => {

            return list.push({
                value: element.code,
                label: element.name,
            })
        })
        setTypes(list)
    }, [activitiesTypes])

    const columnsContact = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
        },
        {
            name: 'Tipo',
            selector: row => row.cntType,
            sortable: false,
        },
        {
            name: 'Nombre Completo',
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: false,
        },
        {
            name: 'Teléfono 1',
            selector: row => row.tel1,
            sortable: false,
        },
        {
            name: 'Teléfono móvil',
            selector: row => row.tel2,
            sortable: false,
        },
        {
            name: 'Correo',
            selector: row => row.e_MailL,
            sortable: false,
        },
    ]

    const onSubmit = async (data) => {
        try {
            const result = await onCreate({
                variables: {
                    input: {
                        actionDate: data.activityDate,
                        actionTime: data.actionTime,
                        details: "Prospección de clientes",
                        notes: data.notes,
                    }
                }
            })
            AlertApp({ type: 'infoToast', message: 'Operacion finalizada con exito' })
            toggle()
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        }
    }
    return (
        <Modal isOpen={_dialog} toggle={toggle} size='xl' className={`card card-primary card-outline`}>
            <ModalHeader>{`${activity?.activityCode ? 'A' : 'Registrar a'}ctivdad de prospección`}</ModalHeader>
            <ModalBody>
                <FormProvider {...methods}>
                    <form>
                        <Row>
                            <Col>
                                <InputApp name={"activityProperty"} label={"Actividad"} control={control} isReadOnly={true} />
                            </Col>
                            <Col>
                                <InputApp name={"title"} label={"Objetivo"} defaultValue={"Prospección de clientes"} control={control} isReadOnly={true} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputApp name={"activityType"} label={"Tipo"} inputType={"select"} listOfOptions={types} control={control} isReadOnly={true} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputApp name={"activityDate"} label={"Fecha de actividad"} inputType='date' control={control} />
                            </Col>
                            <Col>
                                <InputApp name={"actionTime"} label={"Hora de actividad"} inputType='datetime' control={control} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputApp name={"notes"} label={"Comentarios"} inputType='textarea' control={control} />
                            </Col>
                        </Row>
                    </form>
                </FormProvider>
                {activity && (<Row>
                    <Col>
                        <Row>
                            <Col>
                                <h5>Contactos</h5>
                            </Col>
                            <Col lg={{ size: 1, offset: 6 }}>
                                <ButtonApp text={"Agregar"} additionalClass={"btn-md"} color="success" actionButtonType={"button"} action={() => toggleContacts()} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DataTableApp
                                    noDataText={"No se han añadido contactos"}
                                    data={activity?.contacts}
                                    columns={columnsContact}
                                    dense
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>)}

                <ModalFooter>
                    <div className="control-btn-box">
                        {!activity?.activityCode && (
                            <button type="submit" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
                                <FontAwesomeIcon icon={"fa-solid fa-file-circle-check"} /> {`Crear`}
                            </button>
                        )}

                        <button type="button" className="btn btn-secondary" onClick={toggle}>
                            Cerrar
                        </button>
                    </div>
                </ModalFooter>

            </ModalBody>


        </Modal>
    )
}

export default RegisterProspectingModal