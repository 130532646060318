import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { Row, ButtonGroup, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_ALL_INTERNAL_TRANSFER } from '../../../graphql/Catalogs/Warehouse'
import { AlertApp } from '../../Global/AlertApp'
import DataTableApp from '../../Layout/DataTableApp'

const InternalTransferList = () => {
    const navigate = useNavigate()
    const [listOfTransfer, setListOfTransfer] = useState([])
    const [pagination, setPagination] = useState({
        skip: 0,
        take: 100,
    })
    const { loading, error, data } = useQuery(GET_ALL_INTERNAL_TRANSFER, {
        variables: {
            skip: pagination.skip,
            take: pagination.take,
        },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            } else {
                if (data) {
                    setListOfTransfer(data.allInternalTransfer.items)
                }
            }
        }
    }, [loading, error, data])
    const addButtonsTools = () => {
        return <>
            <ButtonGroup>
                <a className="btn btn-secondary" onClick={() => navigate('add')}>
                    <FontAwesomeIcon icon={"share"} />{" Nuevo"}
                </a>
            </ButtonGroup>
        </>
    }
    return (
        <DashboardLayout>
            <CardBox
                title={"Traspasos internos"}
                actionTools={addButtonsTools()}
                content={<>
                    <Row>
                        <Col>
                            <DataTableApp
                                dense
                                stripeed
                                data={listOfTransfer}
                                columns={[
                                    {
                                        name: 'Lote / Num Serie',
                                        selector: row => row.batchNum,
                                        sortable: true,
                                    },

                                    {
                                        name: 'SKU',
                                        selector: row => `${row.itemCode} - ${row.itemName}`,
                                        sortable: true,
                                    },
                                    {
                                        name: 'De ubicación',
                                        selector: row =>  row.type === "OUT" ? row.sourceTo?.name : '',
                                        sortable: true,
                                    },
                                    {
                                        name: 'A ubicación',
                                        selector: row => row.type === "IN" ? row.sourceTo?.name : '',
                                        sortable: true,
                                    },
                                    {
                                        name: 'Cantidad',
                                        selector: row => row.quantity,
                                        sortable: true,
                                    },
                                ]}
                            />
                        </Col>

                    </Row>
                </>}
            />
        </DashboardLayout>
    )
}

export default InternalTransferList