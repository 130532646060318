import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../../Layout/DashboardLayout'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import CardBox from '../../../Layout/CardBox'
import { DELETE_QUOTATION_OPPORTUNITY_CONTENT, GET_ALL_OPPORTUNITIES_DEFECT_CAUSE, GET_ALL_QUOTATION_OPPORTUNITIES, UPDATE_QUOTATION_OPPORTUNITY, DELETE_QUOTATION_OPPORTUNITY } from '../../../../graphql/Catalogs/Opportunities'
import { AlertApp } from '../../../Global/AlertApp'
import DataTableApp from '../../../Layout/DataTableApp'
import { DocOpptSBO, DocStatesSBOColors } from '../../../../Types'
import { Badge, Col, Progress, Row } from 'reactstrap'
import InputApp from '../../../Layout/InputApp'
import moment from 'moment'
import CloseOpportunityModal from './CloseOpportunityModal'
import ActionButtonsForRows from '../../../Layout/ActionButtonsForRows'
import { GET_ALL_COMMERCIAL_STAGES } from '../../../../graphql/Catalogs/CommercialStage'
import { currencyFormat } from '../../../../Utilities'
import { AuthContext } from '../../../../AuthContext'
import { GET_RATE } from '../../../../graphql/QueryManager'
import CardInfo from '../../../Layout/CardInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const QuotationOpportunities = () => {
    const { user: _userContext } = useContext(AuthContext)
    const [listOfOportunities, setListOfOportunities] = useState([])
    const [filtertList, setFilterList] = useState([])
    const [listOfDefectCause, setListOfDefectCause] = useState([])
    const [listOfStages, setListOfStages] = useState([])
    const [selectedRow, setSelectedRow] = useState({})
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const { control, getValues } = useForm()

    const { loading, data, error, refetch } = useQuery(GET_ALL_QUOTATION_OPPORTUNITIES, {
        variables: {
            start: startDate,
            end: endDate,
        }
    })
    const { loading: loadingStages, error: errorStages, data: dataStages } = useQuery(GET_ALL_COMMERCIAL_STAGES)
    const { loading: loadingDefectCause, error: errorDefectCause, data: dataDefectCause, } = useQuery(GET_ALL_OPPORTUNITIES_DEFECT_CAUSE, {
        fetchPolicy: 'cache-and-network'
    })

    const [updateQuotation] = useMutation(UPDATE_QUOTATION_OPPORTUNITY)
    const [deleteRow, { loading: onDeleteRow }] = useMutation(DELETE_QUOTATION_OPPORTUNITY_CONTENT)
    const [deleteQuotation, { loading: onDeleteQuotation }] = useMutation(DELETE_QUOTATION_OPPORTUNITY)
    const [getRate] = useLazyQuery(GET_RATE)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            } else {

                let list = []
                let dates = []
                data.allQuotationOpportunities.map((element) => {
                    let buttons = <ActionButtonsForRows
                        baseUrl={'crm/QuotationOpportunities'}
                        element={element}
                        editButton={element.status === "O"}
                        deleteAction={() => submitDeleteQuotation(element)}
                        deleteButton={element.status === "O" && readOnlyRows}
                        closeOpportunityButtonOptions
                        closeOpportunityOptionsAction={() => {
                            setSelectedRow(element)
                            setModalIsOpen(true)
                        }}
                    />

                    let cloneElement = { ...element }
                    cloneElement.actions = buttons
                    if (dates.some((e) => e === element.start))
                        dates.push(element.start)
                    return list.push(cloneElement)
                })
                setListOfOportunities(list)
                setFilterList(list)

            }
        }
    }, [loading, data, error, startDate, endDate])

    useEffect(() => {
        if (!loadingDefectCause) {
            if (errorDefectCause) {
                AlertApp({ type: 'errorToast', message: errorDefectCause })
            } else {
                let list = []

                dataDefectCause.allOpportunitiesDefectCause.map((element) => {
                    return list.push({
                        value: element.num,
                        label: element.descript,
                        target: element,
                    })
                })
                setListOfDefectCause(list)
            }
        }
    }, [loadingDefectCause, errorDefectCause, dataDefectCause])

    useEffect(() => {
        if (!loadingStages) {
            if (errorStages) {
                AlertApp({ type: 'errorToast', message: errorDefectCause })
            } else {
                let list = []

                dataStages.allCommercialStage.map((element) => {
                    if (element.baseID === 2)
                        return list.push({
                            value: element.stepId,
                            label: element.description,
                            target: element,
                        })
                })
                setListOfStages(list)
            }
        }
    }, [loadingStages, errorStages, dataStages])

    const isWaiting = loading
    const readOnlyRows = (_userContext.Rol === 4 || _userContext.Rol === 1)

    const columns = [
        {
            name: '#',
            selector: row => row.opprId ? `${row.opprId}` : row.id,
            width: '80px',
        },
        {
            name: 'Fecha de apertura',
            selector: row => moment(new Date(row.start)).format('YYYY-MM-DD'),
            width: '130px',
        },
        {
            name: 'Socio de negocio',
            selector: row => `${row.cardCode} - ${row.cardName}`,
            sortable: false,
        },
        {
            name: 'Status',
            selector: row => <Badge color={DocStatesSBOColors[row.status]}> {DocOpptSBO[row.status]}</Badge>,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Representante',
            selector: row => row.salesPerson?.slpName,
        },
        {
            name: 'Objetivo',
            selector: row => row.title,
        },
        {
            name: 'Potencial',
            selector: row => currencyFormat(row.maxSumLoc, row.currency),
        },
        {
            name: 'Etapa',
            selector: row => row.stage?.name,
        },
        {
            name: 'Progreso',
            width: '500 px',
            selector: row => row.stage?.cloPrcnt !== undefined ? <Row>
                <Col sm={4}><label>{`${row.stage?.cloPrcnt} %`}</label></Col>
                <Col sm={8}><Progress value={row.stage?.cloPrcnt} style={{
                    width: '150px'
                }}> {`${row.stage?.cloPrcnt} %`} </Progress></Col>
            </Row> : `Error en etapa`,
            sortable: false,
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]
    const submitDeleteQuotation = async (element) => {

        var htmlString = `
        Se va <b class='text-danger'>Cancelar</b> la Solicitud de precio <b>${element.opprId}</b> del cliente 
        <b>${element.cardCode} - ${element.cardName}</b>
      `
        const result = await AlertApp({
            html: htmlString,
            type: 'question',
            okButtonText: 'Confirmar'
        })

        if (result.isConfirmed) {
            deleteQuotation({
                variables: {
                    id: element.opprId,
                },
                refetchQueries: refetch
            })
            AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
        }
    }
    return (
        <DashboardLayout>
            <CardBox
                title={"Solicitudes de precio especial"}
                loading={isWaiting}
                cardTools
                content={<>
                    <Row>
                        {listOfStages.map((element) => {
                            return <Col key={element.value} md={2}>
                                <CardInfo title={element.label} value={filtertList.filter((row) => row.currentStage === element.value).length} />
                            </Col>
                        })}
                        <Col md={2}>
                            <CardInfo title={"Perdidas"} value={filtertList.filter((row) => row.status === "L").length} />
                        </Col>
                        <Col md={2}>
                            <CardInfo title={"Ganadas"} value={filtertList.filter((row) => row.status === "W").length} />
                        </Col>
                    </Row>
                    <Row>

                        <Col md={{ size: 3, offset: 8 }}>
                            <Row>
                                <Col>
                                    <InputApp name={"start"} label={"Fecha desde"} inputType='date' control={control} changeAction={(e) => {
                                        setStartDate(getValues("start"))
                                        !loading ?? refetch()
                                    }} />
                                </Col>
                                <Col>
                                    <InputApp name={"end"} label={"Fecha hasta"} inputType='date' control={control} changeAction={() => {
                                        setEndDate(getValues("end"))
                                        !loading ?? refetch()
                                    }} />
                                </Col>
                                <Col>
                                    <button type="button" className="btn btn-primary" onClick={() => refetch()}>
                                        <FontAwesomeIcon icon={"fa-solid fa-refresh"} /> Actualizar
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <DataTableApp
                                data={filtertList}
                                columns={columns}
                                striped
                                filter
                                dense
                            />
                        </Col>
                    </Row>


                </>}
            />
            <CloseOpportunityModal
                _user={_userContext}
                _Opportunity={selectedRow}
                _setOpportunity={setSelectedRow}
                _setDialog={setModalIsOpen}
                _dialog={modalIsOpen}
                _listOfStages={listOfStages}
                _listOfDefectCause={listOfDefectCause}
                _update={updateQuotation}
                _deleteRow={deleteRow}
                _refetch={refetch}
                _getRate={getRate} />
        </DashboardLayout>
    )
}

export default QuotationOpportunities