import React, { useState, useEffect } from 'react'
import { DashboardLayout } from '../../../Layout/DashboardLayout'
import CardBox from '../../../Layout/CardBox'
import { useMutation, useQuery } from '@apollo/client'
import { AlertApp } from '../../../Global/AlertApp'
import DataTableApp from '../../../Layout/DataTableApp'

// apollo
import { DELETE_DANGEROUES_GOOD, GET_ALL_DANGEROUS_GOODS } from '../../../../graphql/Catalogs/DangerousGoods'
import ActionButtonsForRows from '../../../Layout/ActionButtonsForRows'
import DataTable from 'react-data-table-component'
import { Col, Input, Progress, Row } from 'reactstrap'
import { ScaleNumPH, ScalePH, ScalePHColors, ScrapCategorys } from '../../../../Types'
const ClassesOfDangerousGoodsList = () => {
    const [dataList, setDataList] = useState([])
    const [isDanger, setIsDanger] = useState(true)
    const [alertList, setAlertList] = useState([])
    const [waiting, setWaiting] = useState(false)
    const { loading, data, error, refetch } = useQuery(GET_ALL_DANGEROUS_GOODS, {
        fetchPolicy: 'cache-and-network'
    })
    const [onDelete] = useMutation(DELETE_DANGEROUES_GOOD)

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: error })
            } else {
                let list = []
                data.allDangerouesItems.map((element) => {
                    let cloneElement = { ...element }


                    let buttons = <ActionButtonsForRows
                        baseUrl={'quality/dangerousitems'}
                        element={element}
                        editButton
                        showButton
                        deleteButton
                        deleteAction={() => submitCancel(element)}
                    />

                    cloneElement.actions = buttons
                    return list.push(cloneElement)
                })
                setDataList(list)
            }
        }
    }, [loading, data, error])
    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            width: '80px',
        },
        {
            name: 'No Articulo',
            selector: row => row.itemCode,
            sortable: true,
            width: '80px',
        },
        {
            name: 'Descripción',
            selector: row => row.itemName,
            sortable: true,
        },
        {
            name: 'PH',
            selector: row => row.ph ? <Col><div className='text-center'>{ScalePH[row.pHmin]}</div> <Progress barStyle={{ background: ScalePHColors[Math.round(row.pHmin)] }} min={1} max={14} value={row.pHmin} > {`${row.pHmin}-${row.pHmax}`} </Progress></Col> : 'N/A',
            sortable: false,
        },
        {
            name: 'Notas',
            selector: row => row.notes,
            sortable: false,
        },
        {
            name: 'SGA',
            selector: row => <>{row.images.map((element, index) => <img key={`row-${row.id}-${index}`} src={require(`../../../../assets/images/SGA/${element}`)} width="40px"></img>)}</>,
            sortable: false,
            width: '400px',
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
        },
    ]
    const columnsExpandable = [
        {
            name: '#',
            selector: row => row.id,
            sortable: false,
            width: '80px',
        },
        {
            name: 'Clase UN',
            selector: row => row.substanceType.name,
            sortable: false,
        },
    ]
    const ToogleDetail = ({ data }) => {
        return (
            <DataTable
                data={data.dangerousInfo}
                columns={columnsExpandable}
                striped
                dense
            />
        )
    }
    const handleChange = ({ selectedRows }) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data

        if (selectedRows.length > 0) {
            const items = selectedRows.map(({ itemCode }) => itemCode)
            let list = []
            selectedRows.map((element, index) => {

                element.compatibilityInfo.map((info) => {
                    if (items.includes(info.refID)) {

                        if (info.conventionID === 3) {
                            if (!list.some((not) => not.itemCode == info.refID))
                                list.push({
                                    key: list.length,
                                    className: 'danger',
                                    itemCode: element.itemCode,
                                    message: `${element.itemCode} ${info.refID} Se requiere almacenar por separado. Son incompatibles`
                                })
                        }
                        if (info.conventionID === 1) {

                            if (index > 0) {

                                if (ScaleNumPH[selectedRows[index - 1].pHmin] == 'Ácido') {
                                    list.push({
                                        key: list.length,
                                        className: 'danger',
                                        itemCode: element.itemCode,
                                        message: `${element.itemCode} ${info.refID} Se requiere almacenar por separado. No compatible con ${ScalePH[element.pHmin]}`
                                    })
                                }
                            }
                        }

                    }
                })
            })
            setAlertList(list)
        }
    }

    const submitCancel = async (element) => {
        try {
           
            var htmlString = `
        Se va <b class='text-danger'>Eliminar</b> la sustancia peligrosa <b>${element.itemName}</b>?</p>
        
      `
            const result = await AlertApp({
                html: htmlString,
                type: 'question',
                okButtonText: 'Confirmar'
            })

            if (result.isConfirmed) {
                if (result.value != "") {
                    setWaiting(true)
                    onDelete({
                        variables: {

                            id: element.id,

                        },
                        refetchQueries: refetch
                    })
                    AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })

                }
            }
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        } finally {
            setWaiting(false)
        }
    }

    return (
        <DashboardLayout>
            <CardBox
                title={"Articulos peligrosos"}
                loading={waiting || loading}
                cardTools
                content={<>
                    <Row>
                        <Col>
                            {alertList.map((element) => {
                                return <div key={element.key} className="alert alert-danger alert-dismissible">
                                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                                    <h5><i className="icon fas fa-ban"></i> Alerta!</h5>
                                    {element.message}
                                </div>
                            })}

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTableApp
                                data={dataList}
                                columns={columns}
                                dense
                                selectableRows
                                expandableRows
                                expandableRowsComponent={ToogleDetail}
                                onSelectedRowsChange={handleChange}
                                filter
                            />
                        </Col>
                    </Row>

                </>}
            />
        </DashboardLayout>
    )
}

export default ClassesOfDangerousGoodsList