import React, { useMemo, useState } from "react"
import DataTable, { createTheme } from "react-data-table-component"

import FilterComponent from "./FilterComponent"

export const DataTableApp = ({ data = [], columns = [], filter = false, noDataText = "No se encontrarón datos",
    ...props }) => {
    const [filterText, setFilterText] = useState("")
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',

    }

    const filteredItems = data.filter(
        item =>
            JSON.stringify(item)
                .toLocaleLowerCase()
                .indexOf(filterText.toLocaleLowerCase()) !== -1
    )

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle)
                setFilterText("")
            }
        }
        return (<FilterComponent

            onFilter={e => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
        />)

    }, [filterText, resetPaginationToggle])

    const tableTheme = createTheme('solarized', {
        text: {
            primary: '#268bd2',

        }
    }, 'light');



    return (<>
        <DataTable
            data={filteredItems}
            columns={columns}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            noDataComponent={noDataText}
            striped
            fixedHeader
            subHeader= {filter}
            subHeaderComponent={filter ? subHeaderComponent : null}
            responsive
            highlightOnHover
            
            {...props}
        />
    </>)
}

export default DataTableApp