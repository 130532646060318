import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query allUsers {
    allUsers {
      id
      code
      fullName
      email
    }
  }
`;

export const GET_USER = gql`
  query oneUser($id: Int!) {
  oneUser(id: $id) {
    id
    code
    fullName
    email
    roleID
    warehouseCode
    salesPersonCode
    branchCode
    employeeNo
    role {
      name
      id
    }
  }
}
`;

export const CREATE_USER = gql`
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      id
      code
      fullName
      email
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: Int!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      id
      code
      fullName
      email
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id)
  }
`;
