import React, { useRef } from "react";
import { useController } from 'react-hook-form'
import { debounce } from 'lodash'
import { Badge, Fade, FormGroup, InputGroup, Label, Spinner, UncontrolledTooltip, } from "reactstrap";
import ButtonApp from "./ButtonApp";
import moment from "moment";
import { AlertApp } from "../Global/AlertApp";
export const InputApp = ({
    inputType = 'text',
    defaultValue,
    name,
    label,
    disabled = false,
    autoComplete = "off",
    isReadOnly = false,
    isLoading = false,
    onlyNumbers = false,
    rows = 3,
    control = null,
    additionalClass = '',
    listOfOptions = '',
    defaultOption = '',
    defaultOptionValue = "",
    optGrpups = false,
    badge,
    badgeText,
    changeAction = () => { },
    blurAction = () => { },
    buttonAction = () => { },
    buttonActionText = '',
    buttonActionToolTip = '',
    buttonIsDisabled = false,
    dataToPersist,
    debounceTime = 500,
    limitCharacters = false,
    customHandleChange,
    noValidations = false,
    faIcon = '',
    validateFormatMsg = 'Elija uno de los formatos sugeridos',
    radioState = null,
    setRadioState = null,
    ...props
}) => {
    const {
        field: { onChange, value },
        fieldState: { invalid, error, isDirty },
    } = useController({ control, name, defaultValue: '' })

    const doAction = useRef(
        debounce((e, dataToPersist) => {
            changeAction(e, dataToPersist)
        }, debounceTime)
    ).current

    const handleChange = (e, dataToPersist) => {
        let value = limitCharacters
            ? handleLimitCharacters(e.target.value, limitCharacters)
            : e.target.value
        onChange(value, dataToPersist)
        doAction(e, dataToPersist)
        changeAction(e, dataToPersist)
    }

    const handleChangeFile = async (e, validateFormat) => {
        const file = e.target.files[0]
        if (!file) return
        const validate = await fileValidator(e.target.files[0], validateFormat)
        if (validate) {
            changeAction(e)
            onChange(e)
        } else {
            onChange('')
            return AlertApp({
                type: 'errorToast',
                message: validateFormatMsg,
            })
        }
    }

    const handleLimitCharacters = (value, limit) => {
        if (value.length > limit) {
            value = value.slice(0, limit)
        }
        return value
    }

    const handleCheck = (e) => {
        onChange(e.target.checked)
        doAction(e)
    }

    async function fileValidator(image, validateFormat) {
        try {
            var blob = image
            var fr = new FileReader()
            const format = validateFormat.toLowerCase()
            return new Promise((resolve) => {
                fr.onload = function (e) {
                    var arr = new Uint8Array(e.target.result).subarray(0, 4)
                    var header = ''
                    for (var i = 0; i < arr.length; i++) {
                        header += arr[i].toString(16)
                    }
                    switch (format) {
                        case 'all':
                            return resolve(true)
                        case 'png': //"PNG"
                            if (header === '89504e47') return resolve(true)
                            else return resolve(false)
                        case 'jpg':
                        case 'jpge': //"JPG JPGE"
                        case 'jpeg':
                            switch (header) {
                                case 'ffd8ffe0':
                                case 'ffd8ffe1':
                                case 'ffd8ffe2':
                                case 'ffd8ffe3':
                                case 'ffd8ffe8':
                                case 'ffd8ffdb':
                                    return resolve(true)
                                default:
                                    return resolve(false)
                            }
                        case 'icon': //"JPG"
                            if (header === '00000100') return resolve(true)
                            else return resolve(false)
                        case 'imageavatar': //JPG, JPGE, PNG,
                            switch (header) {
                                case 'ffd8ffe0':
                                case 'ffd8ffe1':
                                case 'ffd8ffe2':
                                case 'ffd8ffe3':
                                case 'ffd8ffe8':
                                case 'ffd8ffdb':
                                case '89504e47':
                                    return resolve(true)
                                default:
                                    return resolve(false)
                            }
                        case 'pdf': //PDF
                            if (header === '25504446') return resolve(true)
                            else return resolve(false)
                        case 'csv': //CSV
                            if (image.type === 'text/csv') return resolve(true)
                            else return resolve(false)
                        default:
                            return resolve(false)
                    }
                }
                fr.readAsArrayBuffer(blob)
            })
        } catch (e) {
            console.log(e)
            return AlertApp({
                type: 'errorToast',
                message: 'Algo salio mal, vuelve a intentar en unos minutos',
            })
        }
    }
    return (
        <>
            {inputType === 'text' && (
                <>
                    <FormGroup>
                        {label && (
                            <Label for={name}>
                                <b>{label} {isLoading ? (<Spinner size={"sm"} >{''}</Spinner>) : (<></>)} {badge ? (<Badge color="success"> {badgeText} </Badge>) : (<></>)} </b>
                            </Label>
                        )}
                        <input type={onlyNumbers ? "number" : "text"}
                            id={name}
                            name={name}
                            {...props}
                            onChange={(e) => handleChange(e, dataToPersist)}
                            value={defaultValue ? value ? value : defaultValue : value}
                            autoComplete={autoComplete}
                            className={`form-control form-control-md ${invalid ? 'is-invalid' : isDirty ? 'is-valid' : ''} ${additionalClass}`}
                            disabled={disabled || isReadOnly || isLoading}
                        />
                        {error && <p className="error invalid-feedback">{error?.message}</p>}
                    </FormGroup>
                </>
            )}
            {inputType === 'textButton' && (
                <>
                    <FormGroup>
                        {label && (
                            <Label for={name}>
                                <b>{label} {isLoading ? (<Spinner size={"sm"} >{''}</Spinner>) : (<></>)}</b>
                            </Label>
                        )}
                        <InputGroup>
                            <input type={onlyNumbers ? "number" : "text"}
                                id={name}
                                name={name}
                                {...props}
                                onChange={(e) => handleChange(e, dataToPersist)}
                                value={defaultValue ? defaultValue : value ? value : ''}
                                autoComplete={autoComplete}
                                className={`form-control form-control-md  ${invalid ? 'is-invalid' : isDirty ? 'is-valid' : ''} ${additionalClass}`}
                                disabled={disabled || isReadOnly || isLoading}
                            />
                            <ButtonApp
                                data-toggle="tooltip" data-html="true" title={`${buttonActionToolTip}`}
                                id={`textButton-${name}`} additionalClass={"btn-md"} faIcon={isLoading ? 'fa-solid fa-spinner fa-spin' : faIcon === '' ? "fa-solid fa-magnifying-glass" : faIcon} text={buttonActionText} color={"primary"} onClick={(event) => { buttonAction(event) }} disabled={buttonIsDisabled}></ButtonApp>
                            {error && <p className="error invalid-feedback">{error?.message}</p>}
                            {/* <UncontrolledTooltip target={`textButton-${name}`}>{buttonActionToolTip}</UncontrolledTooltip> */}
                        </InputGroup>
                    </FormGroup>
                </>
            )}
            {inputType === 'select' && (
                <>
                    <FormGroup>
                        {label && (
                            <Label>
                                <b>{label} {isLoading ? (<Spinner size={"sm"} >{''}</Spinner>) : (<></>)} {badge ? (<Badge color="success"> {badgeText} </Badge>) : (<></>)} </b>
                            </Label>
                        )}
                        <select
                            name={name}
                            onChange={(e) => handleChange(e, dataToPersist)}
                            className={`custom-select custom-select-md ${invalid ? 'is-invalid' : isDirty ? 'is-valid' : ''} ${additionalClass}`}
                            disabled={disabled || isReadOnly || isLoading}
                            value={value}
                            {...props}
                        >
                            <option disabled value={defaultOptionValue}>
                                Seleccione una opción
                            </option>
                            {listOfOptions.length > 0 ? (
                                <>
                                    {listOfOptions.map(element => {
                                        if (optGrpups) {
                                            return (
                                                <optgroup key={element.type} label={element.category} >
                                                    {element.elements.map((item) => {
                                                        return <option key={item.id} value={item.id}>  {`${item.code ? item.code : ''} - ${item.name}`}</option>
                                                    })}
                                                </optgroup>
                                            )
                                        }
                                        return <option key={element.value} value={element.value} disabled={element.disabled} >  {element.label}</option>
                                    }
                                    )}
                                </>
                            ) : (
                                <option value="" disabled>
                                    No hay datos registrados
                                </option>
                            )}
                        </select>
                        {error && <p className="error invalid-feedback">{error?.message}</p>}
                    </FormGroup>
                </>
            )}
            {inputType === 'duallistbox' && (
                <>
                    <FormGroup>
                        {label && (
                            <Label>
                                <b>{label} {isLoading ? (<Spinner size={"sm"} >{''}</Spinner>) : (<></>)} {badge ? (<Badge color="success"> {badgeText} </Badge>) : (<></>)} </b>
                            </Label>
                        )}

                        <select
                            className="duallistbox"
                            multiple
                            disabled={disabled || isReadOnly || isLoading}>
                            {listOfOptions.length > 0 ? (
                                <>
                                    {listOfOptions.map(element => {
                                        return <option key={element.value} value={element.value} disabled={element.disabled} >  {element.label}</option>
                                    })}
                                </>
                            ) : (
                                <option value="" disabled>
                                    No hay datos registrados
                                </option>
                            )}
                        </select>
                    </FormGroup>
                </>
            )}
            {inputType === 'textarea' && (
                <>
                    <FormGroup>
                        {label && (
                            <Label>
                                <b>{label} {isLoading ? (<Spinner size={"sm"}>{''}</Spinner>) : (<></>)}</b>
                            </Label>
                        )}
                        <textarea
                            {...props}
                            onChange={(e) => handleChange(e, dataToPersist)}
                            disabled={disabled || isReadOnly}
                            value={defaultValue ? value ? value : defaultValue : value}
                            rows={rows}
                            className={`form-control form-control-md  ${invalid ? 'is-invalid' : isDirty ? 'is-valid' : ''} ${additionalClass}`}
                        />
                        {error && <p className="error invalid-feedback">{error?.message}</p>}
                    </FormGroup>
                </>
            )}
            {inputType === 'date' && (
                <>
                    <FormGroup>
                        {label && (
                            <Label>
                                <b>{label} {isLoading ? (<Spinner size={"sm"}>{''}</Spinner>) : (<></>)}</b>
                            </Label>
                        )}
                        <div className={`input-group-append ${invalid ? 'is-invalid' : isDirty ? 'is-valid' : ''}`} data-target={`#datetime${name}`} data-toggle="datetimepicker">
                            <input data-target={`#datetime${name}`}
                                type={"date"}
                                id={name}
                                name={name}
                                {...props}
                                onChange={(e) => handleChange(e, dataToPersist)}
                                value={defaultValue ?
                                    value ? moment(value).format('YYYY-MM-DD') : defaultValue
                                    : moment(value).format('YYYY-MM-DD')}
                                autoComplete={autoComplete}
                                className={`form-control form-control-md  datetimepicker-input ${invalid ? 'is-invalid' : isDirty ? 'is-valid' : ''} ${additionalClass}`}
                                disabled={disabled || isReadOnly || isLoading}
                            />

                        </div>

                        {error && <p className="error invalid-feedback">{error?.message}</p>}
                    </FormGroup>
                </>
            )}
            {inputType === 'datetime' && (
                <>
                    <FormGroup className="time">
                        {label && (
                            <Label>
                                <b>{label} {isLoading ? (<Spinner size={"sm"}>{''}</Spinner>) : (<></>)}</b>
                            </Label>
                        )}

                        <div className="input-group-append" data-target={`#datetime${name}`} data-toggle="datetimepicker">
                            <input data-target={`#datetime${name}`}
                                type={"time"}
                                id={name}
                                name={name}
                                {...props}
                                onChange={(e) => handleChange(e, dataToPersist)}
                                value={defaultValue ? value ? value : defaultValue : value}
                                autoComplete={autoComplete}
                                className={`form-control form-control-md  datetimepicker-input ${invalid ? 'is-invalid' : ''} ${additionalClass}`}
                                disabled={disabled || isReadOnly || isLoading}
                            />

                        </div>
                        {error && <p className="error invalid-feedback">{error?.message}</p>}
                    </FormGroup>
                </>
            )}
            {inputType === 'check' && (
                <>
                    <input
                        name={name}
                        {...props}
                        type="checkbox"
                        checked={value
                            ? value
                            : defaultValue
                                ? defaultValue
                                : value}
                        onChange={(e) => handleCheck(e)}
                        onBlur={blurAction}
                        // className={`form-check-input ${invalid ? 'is-invalid' : ''} ${additionalClass}`}
                        disabled={disabled || isReadOnly}
                    />
                </>
            )}
            {inputType === 'checkbox' && (
                <>
                    <div className="form-check">
                        <input
                            name={name}
                            {...props}
                            type="checkbox"
                            checked={value}
                            onChange={(e) => handleCheck(e)}
                            onBlur={blurAction}
                            className={`form-check-input form-check-input-md ${invalid ? 'is-invalid' : isDirty ? 'is-valid' : ''} ${additionalClass}`}
                            disabled={disabled || isReadOnly}
                        />
                        {label && (
                            <label className="form-check-label">
                                <b>{label} {isLoading ? (<Spinner size={"sm"} >{''}</Spinner>) : (<></>)}</b>
                            </label>
                        )}
                        {error && <p className="error invalid-feedback">{error?.message}</p>}
                    </div>
                </>
            )}
            {inputType === 'radio' && (
                <>
                    <div className="form-check">
                        <input
                            name={name}
                            {...props}
                            type="radio"
                            checked={value}
                            onChange={(e) => handleCheck(e)}
                            onBlur={blurAction}
                            className={`form-check-input form-check-input-md ${invalid ? 'is-invalid' : isDirty ? 'is-valid' : ''} ${additionalClass}`}
                            disabled={disabled || isReadOnly}
                        />
                        {label && (
                            <label className="form-check-label">
                                <b>{label} {isLoading ? (<Spinner size={"sm"} >{''}</Spinner>) : (<></>)}</b>
                            </label>
                        )}
                        {error && <p className="error invalid-feedback">{error?.message}</p>}
                    </div>
                </>
            )}
            {inputType === 'radioCheck' && (
                <>
                    <div className={"form-group"}>
                        {label && (
                            <label className="form-check-label">
                                <b>{label} {isLoading ? (<Spinner size={"sm"} >{''}</Spinner>) : (<></>)}</b>
                            </label>
                        )}
                        {listOfOptions.length > 0 ? (
                            <>
                                {listOfOptions.map(element => {
                                    return <div className={"form-check"} key={element.value}>
                                        <input
                                            type="radio"
                                            value={element.value}
                                            checked={radioState === element.value}
                                            
                                            onChange={(e) => setRadioState(element.value)}
                                            onBlur={blurAction}
                                            className={`form-check-input form-check-input-md ${invalid ? 'is-invalid' : isDirty ? 'is-valid' : ''} ${additionalClass}`}
                                            disabled={disabled || isReadOnly}
                                        />
                                        <label className={"form-check-label"}>
                                            {element.label}
                                        </label>
                                    </div>
                                })}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            )}
            {inputType === 'toggle' && (
                <>
                    <div className="form-group">
                        <div className="custom-control custom-switch custom-switch-off-success custom-switch-on-info">
                            <input
                                name={name}
                                {...props}
                                type="checkbox"
                                checked={value
                                    ? value
                                    : defaultValue
                                        ? defaultValue
                                        : value}
                                onChange={(e) => handleCheck(e)}
                                onBlur={blurAction}
                                className={`custom-control-input ${invalid ? 'is-invalid' : isDirty ? 'is-valid' : ''} ${additionalClass}`}
                                disabled={disabled || isReadOnly}
                            />
                            <label className="custom-control-label">
                                <b>{label} {isLoading ? (<Spinner size={"sm"} >{''}</Spinner>) : (<></>)}</b>
                            </label>
                        </div>
                    </div>
                </>
            )}
            {inputType === 'file' && (
                <>
                    <FormGroup>
                        <>
                            {label && (
                                <label>
                                    <b>{label}</b>
                                </label>
                            )}
                            <input
                                {...props}
                                type="file"
                                defaultValue={value}
                                onChange={(e) => handleChangeFile(e, "pdf")}
                                accept={"pdf"}
                                onBlur={blurAction}
                                className={`form-control rounded-2 ${invalid && 'is-invalid'
                                    } ${additionalClass}`}
                                readOnly={isReadOnly}
                                disabled={disabled}
                            />
                            <span className="error invalid-feedback">{error?.message}</span>
                        </>
                    </FormGroup>
                </>
            )}
        </>
    )
}

export default InputApp