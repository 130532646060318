import { gql } from "@apollo/client";

export const GET_PURCHASE_ORDER = gql`
  query onePurchaseOrder($docEntry: Int!) {
    onePurchaseOrder(iDocEntry: $docEntry) {
      id
      docEntry
      objType
      docNum
      docType
      docStatus
      docDate
      docDueDate
      cardCode
      cardName
      numAtCard
      totalQuantity
      totalOpenQty
      indicatorName
      whsCode
      sucursal
      content {
        docEntry
        lineNum
        trgetEntry
        baseRef
        baseType
        baseLine
        lineStatus
        itemCode
        dscription
        quantity
        openQty
        whsCode
        warehouse {
          whsCode
          whsName
          u_Sucursal
        }
        treeType
        unitMsr
      }
      receipts {
        id
        baseEntry
        baseType
        itemCode
        itemName
        barcode
        manualScan
        mnfSerial
        lotNumber
        expDate
        quantity
      }
      batchNumberInformation {
      id
      userID
      user {
        fullName
      }
      docEntry
      lineNum
      objType
      itemName
      itemName
      distNumber
      whsCode
      quantity
      commitQty
      status
      expDate
      mnfDate
      indDate
    }
    }
  }
`
export const GET_OPEN_PURCHASE_ORDERS = gql`
  query openPurchaseOrders($skip: Int, $take: Int) {
    openPurchaseOrders(skip: $skip, take: $take) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
      items {
        id
        docEntry
        docNum
        docType
        docStatus
        docDate
        docDueDate
        cardCode
        cardName
        numAtCard
        totalQuantity
        totalOpenQty
        indicatorName
        whsCode
        sucursal        
        content {
          docEntry
          lineNum
          trgetEntry
          baseRef
          baseType
          baseLine
          lineStatus
          itemCode
          dscription
          quantity
          openQty
          whsCode
          warehouse {
            whsCode
            whsName
            u_Sucursal
          }
          treeType
          unitMsr
        }
      }
    }
  }
`
export const GET_OPEN_TRANSFERS_REQUEST = gql`
query openTransfersRequest($skip: Int, $take: Int) {
  openTransfersRequest(skip: $skip, take: $take) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
    items {
      id
      docEntry
      docNum
      docType
      docStatus
      docDate
      docDueDate
      cardCode
      cardName
      numAtCard
      totalQuantity
      totalOpenQty
      indicatorName
      whsCode
      sucursal
      content {
        docEntry
        lineNum
        trgetEntry
        baseRef
        baseType
        baseLine
        lineStatus
        itemCode
        dscription
        quantity
        openQty
        whsCode
        warehouse {
          whsCode
          whsName
          u_Sucursal
        }
        treeType
        unitMsr
      }
    }
  }
}
`
export const GET_OPPORTUNITY_SATAGE = gql`
query oneOpportunityStage($number: Int!) {
  oneOpportunityStage(id: $number) {
    num
    descript
    stepId
    cloPrcnt
    canceled
    userSign
    salesStage
    purStage
  }
}
`
export const GET_ALL_OPPORTUNITIES_STAGES = gql`
query allOpportunitiesStage($type: String) {
  allOpportunitiesStage(type: $type) {
    num
    descript
    stepId
    cloPrcnt
    canceled
    userSign
    salesStage
    purStage
  }
}
`
export const GET_ALL_BUSINESS_PARTNER = gql`
query allBusinessPartners($code: String!, $type: String!) {
  allBusinessPartners(cardCode: $code, cardType: $type) {
    cardCode
    cardName
    cardType
    groupCode
    groupNum
    cmpPrivate
    address
    zipCode
    mailAddres
    mailZipCod
    phone1
    cntctPrsn
    contacts {
      name
      position
      tel1
      tel2
      firstName
      lastName
      e_MailL
    }
    notes
    licTradNum
    validUntil
    listNum
    free_Text
    slpCode
    salesPerson{
      slpName
    }
    currency
    cellular
    city
    county
    country
    mailCity
    mailCounty
    mailCountr
    e_Mail
    cardFName
    validFor
    validFrom
    validTo
    frozenFor
    frozenFrom
    frozenTo    
    state1
    state2
    objType    
    shipType
    debPayAcct
    shipToDef
    block
    mailBlock   
    industryC
    industry {
      indCode
      indName
    }
    territory
    territry {
      territryID
      descript
    }
    blocked
    u_Division 
    u_Branch  
  }
}

`
export const GET_BUSINESS_PARTNER = gql`
query oneBusinessPartner($code: String!, $type: String!) {
  oneBusinessPartner(cardCode: $code, cardType: $type) {
    cardCode
    cardName
    cardType
    groupNum
    groupCode
    cmpPrivate
    address
    zipCode
    mailAddres
    mailZipCod
    phone1
    cntctPrsn
    notes
    licTradNum
    validUntil
    listNum
    free_Text
    slpCode
    currency
    cellular
    city
    county
    country
    mailCity
    mailCounty
    mailCountr
    e_Mail
    cardFName
    validFor
    validFrom
    validTo
    frozenFor
    frozenFrom
    frozenTo    
    state1
    state2
    objType    
    shipType
    debPayAcct
    shipToDef
    block
    mailBlock   
    industryC
    territory
    u_Division  
    u_Branch
    contacts {
      cntctCode
      cardCode
      name
      firstName
      lastName
      position
      address
      tel1
      tel2
      cellolar
      e_MailL
    } 
  }
}
`
export const GET_ALL_SALES_PERSONS = gql`
query allSalesPersons {
  allSalesPersons {
    slpCode
    slpName  
    email   
    locked
    active
  }
}
`
export const GET_ALL_TERRITORYS = gql`
query allTerritrys {
  allTerritrys {
    territryID
    descript
  }
}
`
export const GET_ALL_INDUSTRY = gql`
query allIndustrys {
  allIndustrys {
    indCode
    indName
    indDesc
  }
}
`
export const GET_ALL_INTEREST_LEVEL = gql`
query allInterestLevel {
  allInterestLevel {
    num
    descript
    sort
  }
}
`
export const GET_BUSINESS_PARTNER_CONTACT_PERSONS = gql`
query allContactPersonByCard($code: String!) {
  allContactPersonByCard(cardCode: $code) {
    cntctCode
    cardCode
    name
    firstName
    lastName
    position
    address
    tel1
    tel2
    cellolar
    e_MailL
  }
}
`
export const GET_ALL_COUNTRIES = gql`
query allCountries {
  allCountries {
    code
    name
  }
}`
export const GET_ALL_STATES = gql`
query allStates($country: String!) {
  allStates(country: $country) {
    code
    name
    country
  }
}`

export const GET_ALL_BRANCHS = gql`
query allBranches {
  allBranches {
    code
    name
  }
}`
export const GET_EMPLOYEECARD = gql`
query employee($empID: String!) {
  employee(empID: $empID) {
    employeeID
    lastName
    firstName
    middleName
    gender
    department
    branch
    workStreet
    workBlock
    workZipCode
    workCity
    workCountryCode
    workStateCode
    manager
    officePhone
    mobilePhone
    pager
    homePhone
    eMail
    startDate    
    employeeCosts
    employeeCostUnit
    homeStreet
    homeBlock
    homeZipCode
    homeCity
    homeCountry
    homeState
    dateOfBirth
    countryOfBirth
    martialStatus
    numOfChildren
    idNumber
    citizenshipCountryCode
    picture
    remarks
    salaryCurrency
    employeeCostsCurrency
    position
    taxClass
    incomeTaxLiability
    religion
    partnerReligion
    exemptionAmount
    exemptionUnit
    additionalAmount
    additionalUnit
    healthInsuranceType
    professionStatus
    educationStatus
    personGroup
    deviatingBankAccountOwner
    externalEmployeeNumber
    paymentMethod
    accountantResponsible
    legalRepresentative
    dirfResponsible
    active
    passportIssuer
    qualificationCode
    prWebAccess
    previousPRWebAccess
    workStreetNumber
    homeStreetNumber
    createDate
    createTime
    updateDate
    updateTime
    employeeCode
    aRetSEFAZ
    u_BXPAutBD
    u_BXPAutOC
    u_BXPAutOT
    u_BXPAutBT
    u_BXPAutBA
    u_BXPAutBL
    u_BXPAutDl
    u_BXPAutCN
    u_BXPAutFR
    u_BXPAutGI
    u_BXPAutGR
    u_BXPAutGP
    u_BXPAutIP
    u_BXPAutMT
    u_BXPAutPa
    u_BXPAutPk
    u_BXPAutPD
    u_BXPAutPL
    u_BXPAutPR
    u_BXPAutQC
    u_BXPAutRB
    u_BXPAutRP
    u_BXPAutRE
    u_BXPAutSO
    u_BXPAutSR
    u_BXPAutCt
    u_BXPAutST
    u_BXPAutTR
    u_RH_Razon_Social
    u_RH_Clasificacion
    u_RH_CURP
    u_RH_Tipo_Sanguineo
    u_RH_Contacto
    u_RH_Parentesco
    u_RH_Modalidad
    u_RH_TipoContrato
    u_RH_Puesto
    u_RH_Direccion
    u_RH_Subarea
    u_RH_Renovacion
    u_RH_Renovacion2
    u_puesto2
    u_CorreoGalber
    u_NombrePref
    u_Division
    u_CorreoJD
    u_Correo_
    u_Celular
    employeeAbsenceInfoLines {
      employeeID
      lineNum
      fromDate
      toDate
      reason
    }
    vacationDetails {
      empID
      code
      firstName
      lastName
      periodo
      antig_edad
      diasVacaciones
      diasVacaciones
      periodoVacacional
      startDate
      fila
    }
  }
}
`
export const USE_SCAN = gql`
query Scan($qr: String!) {
  useScan(sQR: $qr) {
    itemCode
    itemName
    source
    target {
      itemCode
      itemName
      invntryUom
      clase
      hazardousMaterial
    }
  }
}`

export const GET_RATE = gql`
query rate($currency: String!, $date: DateTime) {
  rate(currency: $currency, date: $date)
}`

export const GET_WAREHOUSE_KPIS = gql`
query warehouseKPIS($code: String!, $week: [Int!]!) {
  warehouseKPIS(sCode: $code, iWeek: $week){
         id
         week
         whsCode
         kpi
         kpi_Name
         kpi_Value
         kpi_Unit
  }
}`

export const GET_SALESPERSON_KPIS = gql`
query salesPersonKPIS($code: Int!, $week: [Int!]!) {
  salesPersonKPIS(iCode: $code, iWeek: $week){
         id
         week
         whsCode
         kpi
         kpi_Name
         kpi_Value
         kpi_Unit
  }
}`