import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '../../Layout/DashboardLayout';
import CardBox from '../../Layout/CardBox';
import { useQuery, useSubscription, } from '@apollo/client';
import moment from 'moment'
import ActionButtonsForRows from '../../Layout/ActionButtonsForRows';

// apollo
import { GET_OPEN_PURCHASE_ORDERS } from '../../../graphql/QueryManager';
import { GET_ALL_BATCH_INFORMATION_ORDER, GET_ALL_COMMODITIES_RECEPTION, SUBSCRIPTION_COMMODITIES_RECEIPTS_CANCELED, } from '../../../graphql/Catalogs/MerchandiseReception/CommoditiesReceipt';
import DataTableApp from '../../Layout/DataTableApp';
import { AlertApp } from '../../Global/AlertApp';
import { GET_CALENDAR_PURCHASE_ORDER } from '../../../graphql/Contexts/Calendar';
import { ButtonGroup, Card, CardBody, CardHeader, CardTitle, Col, List, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import CalendarApp from '../../Layout/CalendarApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DownloadingFiles } from '../../../Utilities';
import CardInfo from '../../Layout/CardInfo';

const PurchaseOrdersList = () => {
    const [purchaseOrders, setPurchaseOrders] = useState([])
    const [listOfReceipts, setListOfReceipts] = useState([])
    const [requestOrders, setRequestOrders] = useState([])
    const [filterOrders, setFilterOrders] = useState([])
    const [filterRequests, setFilterRequests] = useState([])

    const [events, setEvents] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [viewType, setViewType] = useState('OC')
    const [pagination, setPagination] = useState({
        skip: 0,
        take: 200,
    })
    const { loading, error, data, refetch } = useQuery(GET_OPEN_PURCHASE_ORDERS, {
        variables: {
            skip: pagination.skip,
            take: pagination.take,
        },
        fetchPolicy: 'cache-and-network'
    })
    const { loading: loadingCalendar, error: errorCalendar, data: dataCalendar } = useQuery(GET_CALENDAR_PURCHASE_ORDER, {
        fetchPolicy: 'cache-and-network'
    })
    const { loading: loadingReceipts, error: errorReceipts, data: dataReceipts, refetch: refetchReceipts } = useQuery(GET_ALL_COMMODITIES_RECEPTION, {
        fetchPolicy: 'cache-and-network',
        variables: {
            skip: pagination.skip,
            take: pagination.take,
            status: "O"
        }
    })
    const { loading: loadingRequest, data: dataRequest, error: errorRequest } = useQuery(GET_ALL_BATCH_INFORMATION_ORDER, {
        fetchPolicy: 'cache-and-network'
    })
    const { loading: loadingCanceled, data: dataCanceled, error: errorCanceled, } = useSubscription(SUBSCRIPTION_COMMODITIES_RECEIPTS_CANCELED, {
        fetchPolicy: 'cache-and-network',

    })

    useEffect(() => {
        if (!loading) {
            if (error) {
                AlertApp({ type: 'errorToast', message: `OC: ${error}` })
            }
            let list = []
            data.openPurchaseOrders.items.map((element) => {

                let buttons = <ActionButtonsForRows
                    baseUrl={'shopping/purchaseorders'}
                    element={element}
                    showButton
                />
                let cloneElement = { ...element }
                cloneElement.actions = buttons
                return list.push(cloneElement)
            })
            setPurchaseOrders(list)
            setFilterOrders(list)
        }
    }, [loading, error, data])

    useEffect(() => {
        if (!loadingCalendar) {
            if (errorCalendar) {
                AlertApp({ type: 'errorToast', message: errorCalendar })
            } else {
                setEvents(dataCalendar.calendarPurchaseOrders)

            }
        }
    }, [loadingCalendar, dataCalendar, errorCalendar])

    useEffect(() => {
        if (!loadingReceipts) {
            if (errorReceipts) {
                AlertApp(
                    {
                        type: 'errorToast',
                        message: `CR: ${errorReceipts}`
                    }
                )
            } else {

                setListOfReceipts(dataReceipts.allCommoditiesReceipts.items)
            }
        }

    }, [loadingReceipts, errorReceipts, dataReceipts])

    useEffect(() => {
        if (!loadingRequest) {
            if (errorRequest) {
                AlertApp({
                    type: 'errorToast', message: `BNI: ${errorRequest}`
                })
            } else {

                setRequestOrders(dataRequest.allBatchNumberInformation.filter((element) => element.document.docStatus === "O"))
            }
        }

    }, [loadingRequest, errorRequest, dataRequest])

    useEffect(() => {
        if (!loadingCanceled) {
            if (errorCanceled) {
                AlertApp({ type: 'errorToast', message: error })
            }
            if (dataCanceled.commodityReceiptCanceled) {
                AlertApp({ type: 'okToast', message: `La operacion de recepcion ${dataCanceled.commodityReceiptCanceled.id}` })
                refetchReceipts()
            }
        }
    }, [loadingCanceled, errorCanceled, dataCanceled])

    const columns = [
        {
            name: '#',
            selector: row => row.docNum,
            sortable: false,
            right: true,
            width: '80px'
        },
        {
            name: 'Sucursal',
            selector: row => row.sucursal,
            sortable: false,
            compact: true
        },
        {
            name: 'Departmento',
            selector: row => row.indicatorName,
            sortable: false,
            compact: true
        },
        {
            name: 'Fecha de contabilización',
            selector: row => row.docDate,
            sortable: false,
            compact: true,
            format: row => moment(row.docDate).format('YYYY-MM-DD'),
        },
        {
            name: 'Fecha de entrega',
            selector: row => row.shipDate,
            sortable: false,
            hide: 'sm',
            format: row => moment(row.docDueDate).format('YYYY-MM-DD'),
        },
        {
            name: 'Codigo de proveedor',
            selector: row => `${row.cardCode} - ${row.cardName}`,
            sortable: false,
            grow: 2,
        },
        {
            name: 'Total',
            selector: row => row.totalQuantity,
            sortable: false,
            right: true,
            hide: 'sm',
        },
        {
            name: 'Pendiente',
            selector: row => row.totalOpenQty,
            sortable: false,
            right: true,
            hide: 'sm',
        },
        {
            name: 'Solicitudes RQS',
            selector: row => requestOrders.filter((s) => s.docEntry == row.docEntry).length
        },
        {
            name: '',
            selector: row => row.actions,
            sortable: false,
            hide: 'md',
        },
    ]
    const columnsRQS = [
        {
            name: 'OC',
            selector: row => row.document?.docNum,
            sortable: true,
        },
        {
            name: 'Lote / Num Serie',
            selector: row => row.distNumber,
            sortable: true,
        },
        {
            name: 'Solicitante',
            selector: row => row.user?.fullName,
            sortable: true,
        },
        {
            name: 'SKU',
            selector: row => `${row.itemCode} - ${row.itemName}`,
            sortable: true,
        },
        {
            name: 'Fecha de certificado',
            selector: row => moment(row.certDate).format('YYYY-MM-DD HH:mm'),
            sortable: true,
        },
        {
            name: 'Hora de certificado',
            selector: row => row.status === '0' ? moment(row.certDate).locale('es-mx').fromNow() : `Completado`,
            sortable: true,
        },
        {
            name: 'Cantidad',
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: 'COA',
            selector: row => <>

                {row.status === "-1" && (
                    <>
                        <button
                            id={`updateBNI${row.id}`}
                            key={`updateBNI${row.id}`}
                            hidden={row.status === "-1"}
                            onClick={() => {
                                toggle()
                                setBatchInfo(row)
                            }}
                            className={`btn btn-${row.status === "1" ? "success" : "secondary"} btn-sm`}
                        ><FontAwesomeIcon icon={`fa-solid ${row.status === "1" ? "fa-file-circle-check" : "fa-file-circle-question"}`} /></button>
                        {/* <UncontrolledTooltip target={`validate${row.id}`}>{`${row.status === "1" ? "Certificado Valido" : "Completar certificado"}`}</UncontrolledTooltip> */}
                    </>
                )}

            </>,
            sortable: false,
        },

    ]
    const toolButtons = () => {
        return <>
            <ButtonGroup>
                <button type="button" className={"btn btn-primary"} onClick={() => setModalIsOpen(!modalIsOpen)} ><i className={"ion ion-calendar"}> </i>{' Calendario'}</button>
            </ButtonGroup>
        </>
    }
    return (
        <DashboardLayout>
            <Row>
                <Col lg={2} md={2}>
                    <Card>
                        <CardHeader>
                            <CardTitle>Galber</CardTitle>
                            <div className={"card-tool"}>
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus"></i>
                                </button>
                            </div>
                        </CardHeader>
                        <CardBody className={"p-0"}>
                            <ul className={"nav nav-pills flex-column"}>
                                <li className="nav-item active">
                                    <a href="#" className="nav-link" onClick={() => {
                                        setFilterOrders(purchaseOrders)
                                        setViewType("OC")
                                    }}>
                                        <FontAwesomeIcon icon={"fa-solid fa-file-lines"} />{' Abiertas'}
                                        <span className="badge bg-primary float-right">{purchaseOrders.length}</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link" onClick={() => {
                                        const list = []
                                        listOfReceipts.map((element) => {
                                            if (purchaseOrders.some((info) => info.docEntry === element.docEntry)) {
                                                list.push(purchaseOrders.find((info) => info.docEntry === element.docEntry))
                                            }
                                        }
                                        )
                                        console.log(list)
                                        setFilterOrders(list)
                                        setViewType("OC")
                                    }}>
                                        <FontAwesomeIcon icon={"fa-solid fa-truck-loading"} />{' En proceso'}
                                        <span className="badge bg-primary float-right">{listOfReceipts.filter((info) => info.objType === "22").length}</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link" onClick={() => {
                                        const list = requestOrders.filter((s) => s.status === "-1")
                                        setFilterRequests(list)
                                        setViewType("RQS")
                                    }}>
                                        <FontAwesomeIcon icon={"fa-solid fa-file-circle-exclamation"} />{' RQS Certificado'}
                                        <span className="badge bg-danger float-right">{requestOrders.filter((s) => s.status === "-1").length}</span>
                                    </a>
                                </li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={10} md={10}>
                    <CardBox
                        title={`Lista de ordenes de compra abiertas`}
                        loading={loading}
                        actionTools={toolButtons()}
                        content={
                            <>
                                {viewType === "OC" && (<>
                                    <Row>
                                        <CardInfo title={"Certificados pendientes"} value={requestOrders.filter((s) => s.status === "0").length} actionClick={() => {

                                            const list = []
                                            requestOrders.filter((s) => s.status === "0").map((element) => {
                                                if (purchaseOrders.some((info) => info.docEntry === element.docEntry)) {
                                                    list.push(purchaseOrders.find((info) => info.docEntry === element.docEntry))
                                                }
                                            }
                                            )
                                            if (list === filterOrders) {
                                                setFilterOrders(purchaseOrders)
                                            } else {
                                                setFilterOrders(list)
                                                setViewType("OC")
                                            }
                                        }} />
                                    </Row>
                                </>)}
                                <Row>
                                    <Col lg={12} md={8} >
                                        <DataTableApp
                                            data={viewType === "OC" ? filterOrders : filterRequests}
                                            columns={viewType === "OC" ? columns : columnsRQS}
                                            pagination
                                            striped
                                            dense
                                            filter
                                        />
                                    </Col>
                                </Row>
                                <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)} size={"xl"}>
                                    <ModalHeader>Calendario </ModalHeader>

                                    <ModalBody>
                                        <Row>
                                            <Col>
                                                <CalendarApp data={events} />
                                            </Col>
                                        </Row>

                                    </ModalBody>
                                </Modal>
                            </>
                        }
                    />
                </Col>
            </Row>


        </DashboardLayout>
    )
};

export default PurchaseOrdersList;