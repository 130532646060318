import React, { useContext, useEffect } from 'react';
import { DashboardLayout } from './Layout/DashboardLayout';
import { Button, Row } from 'reactstrap';
import SalesPersonHome from './Dashboard/SalesPersonHome';
import { AuthContext } from '../AuthContext';
import WarehousesHome from './Dashboard/WarehousesHome';


const Home = () => {
  const { user: _user } = useContext(AuthContext)

  

  return (
    <DashboardLayout>
      <h2>Inicio</h2>
      {_user.Rol === 8 || _user.Rol === 1 && (
        <WarehousesHome whsCode={"100"} />
      )}
       {_user.Rol === 8 || _user.Rol === 1 && (
        <WarehousesHome whsCode={"101"} />
      )}
       {_user.Rol === 8 || _user.Rol === 1 && (
        <WarehousesHome whsCode={"102"} />
      )}
      {(_user.Rol === 3 || _user.Rol === 4) && (
        <SalesPersonHome />
      )}
    </DashboardLayout>
  )
}

export default Home;