import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import InputApp from '../../../Layout/InputApp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { isDate, parse } from 'date-fns';
import { AlertApp } from '../../../Global/AlertApp'
import DropzoneInput from '../../../Layout/DropzoneInput'
import { DownloadingFiles } from '../../../../Utilities'
import { AuthContext } from '../../../../AuthContext'
const CompleteCertificate = ({ _certificate, _dialog, _setDialog, _progress = false, _onAuth, _refetch }) => {
    const [uploadFiles, setUploadFiles] = useState([])
    const { user: userContext } = useContext(AuthContext)
    const toggle = () => {
        _setDialog(!_dialog)
    }
    const validationSchema = Yup.object().shape({
        distNumber: Yup.string().required('El numero de lote es obligatorio')
            .test("is-greater", "El numero de lote son min 4 caracteres", function (value) {
                return value.length >= 4
            }),
        expDate: Yup.date().required()
            .transform((value, originalValue) => {
                const parsedDate = isDate(originalValue)
                    ? originalValue
                    : parse(originalValue, "yyyy-MM-dd", new Date())

                return parsedDate
            }).typeError('Se debe seleccionar una fecha valida'),
        commitQty: Yup.number().typeError('La cantidad del Lote debe ser un numero valido')
            .required("La cantidad del Lote es obligatoria.")
            .max(_certificate.quantity, 'La cantidad excede lo permitido!'),
        qualityNote: Yup.string().notRequired(),
    })
    const { control, reset, handleSubmit, setValue,getValues } = useForm({ resolver: yupResolver(validationSchema) })
    useEffect(() => {
        reset(_certificate)
        setValue('expDate', moment(_certificate.expDate).format("yyyy-MM-DD"))
    }, [_certificate])

    const statusColor = {
        "-1": 'danger',
        "0": 'primary',
        "1": 'success'
    }
    const statusDscription = {
        "-1": 'Rechazado',
        "0": '',
        "1": 'Aprobado'
    }
    const onDecline = async () => {
        try {

            await _onAuth({
                variables: {
                    id: _certificate.id,
                    auth: {
                        distNumber: _certificate.distNumber,
                        expDate: _certificate.expDate,
                        commitQty: 0,
                        qualityNote: getValues('qualityNote') ,
                        status: '-1',
                    },
                },
                refetchQueries: _refetch,
            })
            reset()
            toggle()
            AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    const onSubmit = async (data) => {
        try {
            const listFiles = Object.values(uploadFiles).map((file) => {
                return file
            })
            await _onAuth({
                variables: {
                    id: _certificate.id,
                    auth: {
                        distNumber: data.distNumber,
                        expDate: data.expDate,
                        commitQty: data.commitQty,
                        qualityNote: data.qualityNote,
                        qualityCert: listFiles.length > 0 ? listFiles[0] : null,
                        status: userContext.Rol === 6 ? '1' : '0',
                    },
                },
                refetchQueries: _refetch,
                context: {
                    headers: {
                        "GraphQL-Preflight": 1
                    }
                }
            })
            reset()
            toggle()
            AlertApp({ type: 'okToast', message: 'Operacion finalizada con exito' })
        } catch (error) {
            AlertApp({ type: 'errorToast', message: error })
        }
    }
    return (
        <Modal isOpen={_dialog} toggle={toggle} size='xl' className={`card card-${statusColor[_certificate.status]} card-outline`}>
            <ModalHeader>{`Completar certificado: ${_certificate.document?.docNum}-${_certificate.id} ${statusDscription[_certificate.status]}`}</ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} className="p-3 needs-validation">
                <ModalBody>
                    <Row>
                        <Col lg={2}>
                            <InputApp name={"itemCode"} label={"No Articulo"} defaultValue={_certificate.itemCode} control={control} isReadOnly tabIndex={0} />
                        </Col>
                        <Col>
                            <InputApp name={"itemName"} label={"Descripcion"} defaultValue={_certificate.itemName} control={control} isReadOnly tabIndex={0} />
                        </Col>
                        <Col lg={2}>
                            <InputApp name={"line.quantity"} label={"Cantidad OC"} defaultValue={_certificate.line?.quantity} control={control} isReadOnly tabIndex={0} />
                        </Col >
                        <Col lg={2}>
                            <InputApp name={"unitMsr"} label={"Unidad"} defaultValue={_certificate.item?.buyUnitMsr} control={control} isReadOnly tabIndex={0} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <InputApp name={"distNumber"} label={"Lote de Proveedor"} placeholder={"No Lote"} control={control} tabIndex={1} isReadOnly={_certificate.status === "-1"} />
                        </Col>
                        <Col>
                            <InputApp inputType={"date"} name={"expDate"} label={"Fecha de expiracion"} placeholder={"Fecha de vencimiento"} control={control} tabIndex={2} isReadOnly={_certificate.status === "-1"} />
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <InputApp name={"quantity"} label={"Cantidad"} placeholder={"Cantidad"} control={control} onlyNumbers={true} step={".1"} isReadOnly tabIndex={0} />
                                </Col>

                            </Row>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8}>
                            <InputApp name={"qualityNote"} inputType={"textarea"} label={"Comentarios"} placeholder={"Comentarios"} control={control} tabIndex={4} isReadOnly={_certificate.status === "-1"} />
                        </Col>
                        <Col>
                            <InputApp name={"commitQty"} label={"Cantidad Aceptada"} placeholder={"Cantidad Aceptada"} control={control} onlyNumbers={true} step={".1"} isReadOnly={_certificate.status === "-1"} tabIndex={3} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8} hidden={_certificate.status === "-1"}>

                            <DropzoneInput
                                label={`${_certificate.qualityCert ?? "Certificado de calidad"}`}
                                uploadMode={!_certificate.hasQualityCert}
                                downloadAction={_certificate.hasQualityCert ? () => DownloadingFiles("QuaCert", _certificate.id, _certificate.qualityCert, _certificate.qualityCertExt) : null}
                                setUploadFiles={setUploadFiles} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="control-btn-box">
                        <button type="submit" className="btn btn-primary" disabled={!(userContext.Rol === 1 || userContext.Rol === 6)} hidden={_certificate.status === "-1"}>
                            <FontAwesomeIcon icon={"fa-solid fa-file-circle-check"} /> {`${_certificate.status !== "0" ? 'Actualizar' : 'Completar'}`}
                        </button>
                        <button type="button" className="btn btn-danger" disabled={!(userContext.Rol === 1 || userContext.Rol === 6 || userContext.Rol === 6 || userContext.Rol === 8)} hidden={_certificate.status !== "0"} onClick={() => onDecline()}>
                            <FontAwesomeIcon icon={"fa-solid fa-file-circle-xmark"} /> Rechazar
                        </button>

                        <button type="button" className="btn btn-secondary" onClick={toggle}>
                            Cerrar
                        </button>
                    </div>
                </ModalFooter>
            </form>

        </Modal>
    )
}

export default CompleteCertificate