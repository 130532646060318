import React from "react"
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import esLocale from '@fullcalendar/core/locales/es'
import { Tooltip } from "reactstrap"

export default function CalendarApp({
  data,
  raiseEventItem,
  initialView = null,
  ...props }) {
  return (
    <div className="FullCalendar">
      <FullCalendar
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          listPlugin
        ]}
        initialView={initialView ?? "dayGridMonth"}
        expandRows={true}
        events={data}
        locales={esLocale}
        locale={'es'}
        nextDayThreshold= {'08:00:00'}
        titleFormat={{ month: 'long', day: 'numeric', }}
        eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false }}
        dayPopoverFormat={{month: 'long', day: 'numeric', year: 'numeric'}}
        eventMinHeight={30}      
        selectable={true}
        hiddenDays={[6, 7]}
        weekends={false}        
        eventRender={({ event, el }) => {
          const content = <div>{event.title}<div>{event.description}</div></div>;
          ReactDOM.render(content, el);
          return el;
        }}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,list'
        }}
     
        eventContent={raiseEventItem}
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5],
          
        }}
        {...props}
      />
    </div>

  )
}