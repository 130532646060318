import React, { useState, useEffect, useContext } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CardBox from '../../Layout/CardBox'
import { ButtonGroup, Col, FormGroup, Row } from 'reactstrap'
import ChooseFromListModal from '../../Global/ChooseFromListModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_ALL_INVNT_ITEMS } from '../../../graphql/Catalogs/BoItems'
import ProductTagDialog from './ProductTagDialog'
import { GET_ALL_BATCH_BY_ITEMCODE, GET_ALL_PRINT_LOT_TAGS, GET_ALL_WAREHOUSES, SEND_PRINT_LOT_TAGS } from '../../../graphql/Catalogs/Warehouse'
import { AuthContext } from '../../../AuthContext'
import { AlertApp } from '../../Global/AlertApp'
import DataTableApp from '../../Layout/DataTableApp'

const TagLogList = () => {
    const { user: userContext } = useContext(AuthContext)
    const [addModal, setShowAddModal] = useState(false)
    const [chooseFromListItem, setChooseFromListItem] = useState(false)
    const [itemList, setItemList] = useState([])
    const [tagLogsList, setTagLogsList] = useState([])
    const [boItem, setBoItem] = useState({})
    const [listOfBatchs, setListOfBatchs] = useState([])
    const [listOfWarehouses, setListOfWarehouses] = useState([{
        value: "-1",
        label: "Seleccione una opción",
        selected: true,
    }])
    const columns = [
        {
            name: '#',
            selector: row => row.id,
            width: '70px',
        },
        {
            name: 'Creador',
            selector: row => row.userID
        },
        {
            name: 'No articulo',
            selector: row => row.itemCode
        },
        {
            name: 'Descripción',
            selector: row => row.itemName
        },
        {
            name: 'No Lote',
            selector: row => row.distNumber
        },
        {
            name: 'Almacen',
            selector: row => row.whsCode
        },
        {
            name: 'Impresiones',
            selector: row => row.numberOfCopies
        },
    ]
    const columnItems = [
        {
            name: '#',
            selector: row => `${row.itemCode}`,
            width: '80px',
        },
        {
            name: 'Descripcion',
            selector: row => `${row.itemName}`,
        },
        {
            name: 'Unidad',
            selector: row => row.salUnitMsr,
            width: '80px',
        },
        {
            name: 'Paquete',
            selector: row => row.salPackMsr,
            width: '80px',
        },
        {
            name: 'Grupo',
            selector: row => row.itemGroup.itmsGrpNam,
        },

        {
            name: '',
            selector: row => <button
                id={`selectButton${row.id}`}
                key={`selectButton${row.id}`}
                onClick={() => OnSelectRowItem(row)}
                className='btn btn-info btn-sm'
            ><FontAwesomeIcon icon="fa-solid fa-share" /></button>
        },
    ]
    const { loading: loadingItems, error: errorItems, data: dataItems } = useQuery(GET_ALL_INVNT_ITEMS)
    const { loading: loadingWarehouses, error: errorWarehouses, data: dataWarehouses } = useQuery(GET_ALL_WAREHOUSES)
    const { loading: loadingTagLogs, error: errorTagLogs, data: dataTagLogs } = useQuery(GET_ALL_PRINT_LOT_TAGS)

    const [getAllBatchs, { loading: onGetAllBatchs }] = useLazyQuery(GET_ALL_BATCH_BY_ITEMCODE)

    const [sendPrint, { loading: onSendPrint }] = useMutation(SEND_PRINT_LOT_TAGS)

    useEffect(() => {
        if (!loadingItems) {
            if (errorItems) {
                AlertApp({ type: 'errorToast', message: errorItems })
            }

            if (dataItems) {

                setItemList(dataItems.allInvntItems)

            }
        }
    }, [loadingItems, errorItems, dataItems])

    useEffect(() => {
        if (!loadingWarehouses) {
            if (errorWarehouses) {
                AlertApp({ type: 'errorToast', message: error })
            } else {
                if (dataWarehouses.allWarehouses) {
                    let list = []
                    list.push({
                        value: "-1",
                        label: "Seleccione una opción",
                        selected: true,
                    })
                    dataWarehouses.allWarehouses.map((element) => {
                        list.push({
                            value: element.whsCode,
                            label: `${element.whsCode} - ${element.whsName}`,

                        })
                    })

                    setListOfWarehouses(list)
                }
            }
        }
    }, [loadingWarehouses, errorWarehouses, dataWarehouses, useContext])

    useEffect(() => {
        if (!loadingTagLogs) {
            if (errorTagLogs) {
                AlertApp({ type: 'errorToast', message: error })
            } else {
                setTagLogsList(dataTagLogs.allLabelPrintingLog)
            }
        }
    }, [loadingTagLogs, errorTagLogs, dataTagLogs])

    const OnSelectRowItem = async (row) => {
        try {
            setBoItem(row)
            setShowAddModal(!addModal)
            const result = await getAllBatchs({
                variables: {
                    code: row.itemCode,
                    whsCode: userContext.Warehouse
                }
            })
            let list = []

            result.data.allBatchsByItemCode.map((element) => {
                list.push({
                    value: element.absEntry,
                    label: `${element.distNumber} - ${element.whsCode}`,
                    selected: false,
                    content: element,
                })
            })

            setListOfBatchs(list)
        } catch (error) {
            AlertApp({ type: 'error', message: error })
        } finally {
            setChooseFromListItem(!chooseFromListItem)
        }
    }
    const addButtonsTools = () => {
        return (
            <>
                <ButtonGroup>
                    <button type="button" className={"btn btn-sm btn-secondary"} onClick={() => setChooseFromListItem(!chooseFromListItem)}>Crear Etiquetas</button>
                </ButtonGroup>
            </>
        )
    }
    return (
        <DashboardLayout>
            <CardBox
                title={"Bitacora de etiquetas"}
                actionTools={addButtonsTools()}
                content={<>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label>Almacen</label>
                                <select className={`custom-select custom-select-md`}>
                                    <option disabled value={"-1"}>
                                        Seleccione una opción
                                    </option>
                                    {listOfWarehouses.map(element => <option key={element.value} value={element.value} >{element.label}</option>)}
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTableApp
                                data={tagLogsList}
                                columns={columns}
                                dense
                            />
                        </Col>
                    </Row>
                </>}
            />
            <ProductTagDialog
                _dialog={addModal}
                _setDialog={setShowAddModal}
                _boItem={boItem}
                _batchs={listOfBatchs}
                _send={sendPrint}
                _onSend={onSendPrint}
            />
            <ChooseFromListModal
                chooseFromList={chooseFromListItem}
                setChooseFromList={setChooseFromListItem}
                data={itemList}
                columns={columnItems}
                filter={true}
                selectableRows={false}
                title={`Lista de articulos`}
                size={"xl"} />
        </DashboardLayout>
    )
}

export default TagLogList