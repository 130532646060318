import { gql } from "@apollo/client";

export const GET_ALL_WAREHOUSES = gql`
query allWarehouses {
  allWarehouses {
    whsCode
    whsName
    u_Sucursal
  }
}
` 
export const GET_ONE_WAREHOUSE_PARAMETERS = gql `
query oneWarehouseParameterByWhsCode($code: String!) {
  oneWarehouseParameterByWhsCode(code: $code) {
    id
    whsCode
    whsCodeDecrease
    whsCodeReceipt
    whsCodeTransit
    whsDecreaseName
    whsName
    whsReceiptName
    whsTransitName
  }
}`
 
export const GET_ALL_BINLOCATIONS = gql`
query allBinLocations($skip: Int!, $take: Int!) {
  allBinLocations(skip: $skip, take: $take) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
    items {
      absEntry
      binCode
      sysBin
      whsCode
      attr1Val
      name
      content {
        id
        itemCode
        itemName
        binAbs
        onHandQty
        whsCode
        freezed
        uomCode
        clase
        batches {
          absEntry
          snBMDAbs
          whsCode
          binAbs
          binCode
          itemCode
          onHandQty
          itemName
          sysNumber
          distNumber
          mnfSerial
          lotNumber
          expDate
          mnfDate
          inDate
          status
          clase
        }
      }
      countItems
      quantityItems
      isEmpty
    }
  }
}`

export const GET_ONE_BIN_LOCATION = gql`
  query oneBinLocation($code: String!) {
    oneBinLocation(sCode: $code) {
      absEntry
      binCode
      countItems
      quantityItems
      sysBin
      whsCode
      attr1Val
      isEmpty
      content {
        id
        itemCode
        itemName
        binAbs
        onHandQty
        whsCode
        freezed
        uomCode
        clase
        batches {
          absEntry
          snBMDAbs
          whsCode
          binAbs
          binCode
          itemCode
          onHandQty
          itemName
          sysNumber
          distNumber
          mnfSerial
          lotNumber
          expDate
          mnfDate
          inDate
          status
          clase
        }
      }
    }
  }
`;

export const GET_ALL_BATCH_BY_ITEMCODE = gql`
query allBatchsByItemCode($code: String!, $whsCode: String) {
  allBatchsByItemCode(sItemCode: $code, sWhsCode: $whsCode) {
    sysNumber
    absEntry
    distNumber
    expDate
    inDate
    itemCode
    itemName
    lotNumber
    binAbs
    binCode
    clase
    onHandQty
    snBMDAbs
    status
    whsCode
  }
}
`;

export const SUBSCRIPTION_INTERNAL_TRANSFER = gql`
subscription {
  internalTrasferAdded {
    batchNum
    id
    itemCode
    itemName
    quantity
    source
    to
    type
    uomCode
    userID
    whsCode
  }
} `

export const GET_ALL_INTERNAL_TRANSFER = gql`
query allInternalTransfer($skip: Int!, $take: Int!) {
  allInternalTransfer(skip: $skip, take: $take) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
    items {
      id
      userID
      type
      whsCode
      source
      sourceBin {
        name
      }
      to
      sourceTo {
        name
      }
      itemCode
      itemName
      quantity
      uomCode
      batchNum
    }
  }
}
`

export const GET_ALL_PRINT_LOT_TAGS = gql`
query allLabelPrintingLog {
  allLabelPrintingLog {
    id
    userID
    tagType
    attached
    attachedExt
    itemCode
    itemName
    absEntry
    distNumber
    expDate
    quantity
    whsCode
    status
    authorizedBy
    reasonForRequest
    freeText
    printTime
    authorizationTime
    numberOfCopies
    printingError
    jobID
  }
}
` 

export const SEND_PRINT_LOT_TAGS = gql`
mutation sendPrindtCommand($input: PrintTagCommandRequestInput!){
  sendPrindtCommand (input: $input)
}` 